import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  addDoc,
  onSnapshot,
  query,
  collection,
  where,
  writeBatch
} from "firebase/firestore";
import inititializeFirebase from "services/firebase";
import { deleteApp } from "firebase/app";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useAuth } from "./useAuth";
import useEventArticleReader from "database/articles/useEventArticleReader";
import { xor } from "lodash";
import { toast } from "react-toastify";
import useLocalStorage from "helpers/useLocalStorage";

const BulkOrdersContext = createContext();
const db = getFirestore();

const BulkOrdersContextProvider = ({ children }) => {
  const { authContextLoaded, user, login } = useAuth();
  const { retrieveArticlesByIDs } = useEventArticleReader();

  const [currentOrderID, setCurrentOrderID] = useLocalStorage(
    "currentOrderID",
    null
  );

  const [isLoading, setIsLoading] = useState(true);
  const [storeID, setStoreID] = useState();
  const [store, setStore] = useState();
  const [courseInfo, setCourseInfo] = useState();
  const [articlesData, setArticlesData] = useState({});
  const [currentOrder, setCurrentOrder] = useState();

  //Fix to delete Firestore instance on page unload
  //This is an issue in Safari
  useEffect(() => {
    const unloadCallback = () => {
      const firebaseApp = inititializeFirebase();
      console.log(firebaseApp);
      deleteApp(firebaseApp)
        .then(function () {
          console.log("App deleted successfully");
        })
        .catch(function (error) {
          console.log("Error deleting app:", error);
        });
    };
    window.addEventListener("beforeunload", unloadCallback);
    return async () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  useEffect(() => {
    if (!authContextLoaded || storeID == null) return;

    const q = query(
      collection(db, "courses", courseInfo.id, "events", storeID, "packages")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const store = querySnapshot.docs[0]?.data();
      if (!store) return;
      if (store.type !== "bulk") {
        toast.error("This is not a valid Bulk Purchase Store");
        // setStoreID();
      } else {
        const store = querySnapshot.docs[0].data();
        store.id = querySnapshot.docs[0].id;
        store.eventDate = null;
        store.deadline = null;
        setStore(store);
      }
    });

    return () => unsubscribe();
  }, [authContextLoaded, courseInfo?.id, storeID]);

  //Set Article Data on Event Packages
  useEffect(() => {
    if (store == null) return;
    if (
      store.articleData == null ||
      xor(Object.keys(store.articleData), Object.keys(articlesData)).length > 0
    ) {
      setStore((prevState) => ({
        ...prevState,
        articleData: articlesData
      }));
    }
  }, [store, articlesData]);

  //Get Event Articles and modify if the event articles change
  useEffect(() => {
    if (
      store?.allArticles != null &&
      xor(Object.keys(store.allArticles), Object.keys(articlesData)).length > 0
    ) {
      retrieveArticlesByIDs(Object.keys(store.allArticles))
        .then((articles) => {
          setArticlesData(articles);
        })
        .catch((error) => {
          console.log(error);
          setArticlesData({});
        });
    }
  }, [store?.allArticles, retrieveArticlesByIDs, articlesData]);

  //Get Current Order
  useEffect(() => {
    if (storeID == null || store == null) return;
    // const q = query(
    //   collection(db, "courses", courseInfo.id, "events", storeID, "orders"),
    //   where("isOpen", "==", true)
    // );
    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   if (querySnapshot.docs.length === 1) {
    //     setCurrentOrder({
    //       ...querySnapshot.docs[0].data(),
    //       createdAt: querySnapshot.docs[0].data().createdAt.toDate(),
    //       id: querySnapshot.docs[0].id
    //     });
    //   }
    //   setIsLoading(false);
    // });

    if (currentOrderID == null) {
      setIsLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(
        db,
        "courses",
        courseInfo.id,
        "events",
        storeID,
        "orders",
        currentOrderID
      ),
      (doc) => {
        if (doc.exists() && doc.data().isOpen) {
          setCurrentOrder({
            ...doc.data(),
            createdAt: doc.data().createdAt.toDate(),
            id: doc.id
          });
        } else {
          setCurrentOrderID(null);
        }
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [courseInfo?.id, storeID, store, currentOrderID, setCurrentOrderID]);

  const initializeStore = useCallback(
    async (courseID, eventID) => {
      try {
        if (authContextLoaded) {
          if (user == null || !user.possibleTypes?.includes("adidasRep")) {
            await login(`participant@${eventID}.com`, eventID);
          }
          //Get Course Info
          const courseDoc = await getDoc(
            doc(db, "courses", courseID, "public", "generalInfo")
          );
          setCourseInfo({ ...courseDoc.data(), id: courseID });
          setStoreID(eventID);
        }

        return;
      } catch (error) {
        console.log(error);
      }
    },
    [login, authContextLoaded, user]
  );

  const startNewOrder = useCallback(async () => {
    const batch = writeBatch(db);

    if (currentOrder != null) {
      batch.delete(
        doc(
          db,
          "courses",
          courseInfo?.id,
          "events",
          storeID,
          "orders",
          currentOrder.id
        )
      );
    }

    const newOrder = {
      isOpen: true,
      createdAt: new Date(),
      items: {}
    };
    const newOrderRef = doc(
      collection(db, "courses", courseInfo?.id, "events", storeID, "orders")
    );
    console.log(newOrderRef);
    batch.set(newOrderRef, newOrder);

    await batch.commit();
    setCurrentOrderID(newOrderRef.id);
    return;
  }, [courseInfo?.id, currentOrder, setCurrentOrderID, storeID]);

  const finishCurrentOrder = () => {
    setCurrentOrder(undefined);
    setCurrentOrderID(null);
  };

  return (
    <BulkOrdersContext.Provider
      value={{
        isLoading,
        initializeStore,
        store,
        currentOrder,
        finishCurrentOrder,
        startNewOrder,
        courseInfo
      }}
    >
      {children}
    </BulkOrdersContext.Provider>
  );
};

export default BulkOrdersContextProvider;

export const useBulkOrdersDatabase = () => useContext(BulkOrdersContext);
