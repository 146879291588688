import React from "react";
import styled from "styled-components/macro";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";
import BulkCartArticle from "./BulkCartArticle";

const BulkCart = ({
  sectionsData,
  articleData,
  hideMSRP,
  allEventArticles,
  onSelectArticle,
  userSelections,
  allowEdit = true
}) => {
  return (
    <MainWrapper>
      {sectionsData?.map(
        (section) =>
          Object.keys(userSelections).some((articleID) =>
            section.articles.includes(articleID)
          ) && (
            <SectionWrapper key={section.id}>
              <Header>
                <SectionName>{section.name}</SectionName>
              </Header>
              <SectionBody>
                {section.articles.map(
                  (articleID) =>
                    Object.keys(userSelections).includes(articleID) && (
                      //   <div key={articleID}>
                      //     {articleData?.[articleID].articleNumber}
                      //   </div>
                      // )
                      <BulkCartArticle
                        key={articleID}
                        onSelectEditArticle={onSelectArticle}
                        selectedQty={userSelections[articleID]}
                        hideMSRP={hideMSRP}
                        article={{
                          ...articleData?.[articleID],
                          customPrice:
                            allEventArticles?.[articleID]?.customPrice ??
                            articleData?.[articleID]?.retailPrice
                        }}
                        allowEdit={allowEdit}
                      />
                    )
                )}
              </SectionBody>
            </SectionWrapper>
          )
      )}
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Header = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid ${COLORS.secondary};
  border-bottom: 2px solid ${COLORS.secondary};
  background: ${COLORS.pageBackground};
`;

const SectionName = styled.div`
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 5px 15px;
  background: ${COLORS.pageBackground};
`;

export default BulkCart;
