import React, { useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { CSSTransition } from "react-transition-group";

import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import CancelOutlined from "@material-ui/icons/CancelOutlined";

const Modal = ({
  show,
  closeButtonHidden,
  disableCloseOnOutsideClick,
  onClose,
  title,
  height,
  maxHeight,
  width,
  maxWidth,
  contentPadding,
  children,
  className
}) => {
  const nodeRef = useRef();

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <StyledModal
        ref={nodeRef}
        className={className}
        onClick={disableCloseOnOutsideClick ? null : onClose}
      >
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          height={height}
          maxHeight={maxHeight}
          width={width}
          maxWidth={maxWidth}
        >
          {closeButtonHidden ? (
            ""
          ) : (
            <CloseButton onClick={onClose}>
              <CancelOutlined style={{ fontSize: 30 }} />
            </CloseButton>
          )}
          <Container>
            {<Title>{title}</Title>}
            <Content>{children}</Content>
          </Container>
        </ModalContent>
      </StyledModal>
    </CSSTransition>,
    document.getElementById("root")
  );
};

Modal.propTypes = {
  show: PropTypes.bool
};

const StyledModal = styled.div`
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;
  }

  &.exit {
    opacity: 0;
  }
`;

const ModalContent = styled.div`
  @media screen and (max-width: 550px) {
    position: fixed;
    top: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100vh);
    max-height: 85vh;
  }

  @media screen and (min-width: 550px) {
    height: ${(props) => props.height && props.height};
    max-height: ${(props) => props.maxHeight && props.maxHeight};
    width: ${(props) => props.width && props.width};
    max-width: ${(props) => props.maxWidth && props.maxWidth};
    margin: 10px;
    transform: translateY(-200px);
  }

  background-color: white;
  border-radius: ${CONSTANTS.borderRadius};
  position: relative;
  transition: all 0.3s ease-in-out;

  ${StyledModal}.enter-done & {
    transform: translateY(0);
  }

  ${StyledModal}.exit & {
    @media screen and (max-width: 550px) {
      transform: translateY(100vh);
    }

    @media screen and (min-width: 550px) {
      transform: translateY(-200px);
    }
  }
`;

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  justify-items: center;
`;

const Content = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 20px;
  @media screen and (max-width: 550px) {
    padding: 20px 0;
  }
`;

const Title = styled.div`
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 7px;
  width: 30px;
  height: 30px;
  color: ${COLORS.text};
  cursor: pointer;
`;

export default Modal;
