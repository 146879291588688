import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import React from "react";
import styled from "styled-components/macro";
import ContactInfo from "./ContactInfo";
import { useTranslation } from "react-i18next";

const PastDeadlineError = ({ courseInfo, eventInfo }) => {
  const { t } = useTranslation();

  return (
    <Page>
      <Container>
        <Header>
          <Image src={courseInfo?.logoURL || "/icon-adidas-logo.svg"} />
          <CourseName>{courseInfo?.name}</CourseName>
          <EventName>{eventInfo?.eventName}</EventName>
        </Header>
        <MessageWrapper>
          <Message>
            <p>{t("sign_up_past_deadline_message_1")}</p>
            <p>{t("sign_up_past_deadline_message_2")}</p>
          </Message>
        </MessageWrapper>
      </Container>
      {eventInfo.hideContactInfo ? (
        <NoContactSpacer />
      ) : (
        <ContactInfo
          contactInfo={
            eventInfo.customContactInfo || {
              email: courseInfo.email,
              phone: courseInfo.phone
            }
          }
        />
      )}
    </Page>
  );
};

const Page = styled.div`
  padding-top: 3vh;
  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  max-width: 95%;
  padding-bottom: 30px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  justify-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 150px;
  margin-bottom: 10px;

  @media screen and (max-width: 550px) {
    width: 100px;
  }
`;

const CourseName = styled.div`
  padding-top: 10px;
  text-transform: uppercase;
  font-size: ${FONTSIZE.medium};
  color: ${COLORS.text};
`;

const EventName = styled.div`
  text-transform: uppercase;
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
`;

const MessageWrapper = styled.div`
  height: 100%;
  display: flex;
  /* align-items: center; */
  padding-top: 20vh;
`;
const Message = styled.div`
  color: ${COLORS.text};
  font-size: ${FONTSIZE.large};
  text-align: center;
`;

const NoContactSpacer = styled.div`
  height: 80px;
`;

export default PastDeadlineError;
