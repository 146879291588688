import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../database/useAuth";

const SuperAdminProtectedRoute = ({ children, ...rest }) => {
  const { user, authContextLoaded } = useAuth();
  return (
    <Route
      {...rest}
      render={() =>
        user != null &&
        (user.possibleTypes.includes("god") ||
          user.possibleTypes.includes("adidasManager"))
          ? children
          : authContextLoaded && (
              <Redirect
                to={{
                  pathname: "/adminLogin"
                }}
              />
            )
      }
    />
  );
};

export default SuperAdminProtectedRoute;
