import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from "react";

import { useAuth } from "database/useAuth";
import inititializeFirebase from "services/firebase";
import { deleteApp } from "firebase/app";
// import useArticleSearchReader from "database/articles/useArticleSearchReader";
import useEventArticleReader from "database/articles/useEventArticleReader";
import { warningsForEvent } from "helpers/eventWarnings";
// import sizeof from "firestore-size";

import {
  getFirestore,
  Timestamp,
  query,
  orderBy,
  collection,
  collectionGroup,
  where,
  onSnapshot,
  getDocs,
  getDoc,
  doc
} from "firebase/firestore";

import { xor } from "lodash";

//Create Context
const DatabaseReaderContext = createContext();

//Context Provider
const DatabaseReaderContextProvider = ({ children }) => {
  //Internal Variables
  const { user } = useAuth();
  // const { retrieveArticlesByIDs } = useArticleSearchReader();
  const { retrieveArticlesByIDs } = useEventArticleReader();
  const db = getFirestore();
  const [eventsByCourse, setEventsByCourse] = useState({});
  //The states below are to allow for async with firebase
  const [selectedCourseID, setSelectedCourseID] = useState();
  const [selectedEventID, setSelectedEventID] = useState();
  const [selectedEventArticles, setSelectedEventArticles] = useState({});

  //Context State that will be shared from provider
  const [userHasNoAssociatedCourses, setUserHasNoAssociatedCourses] =
    useState(false);
  const [courses, setCourses] = useState([]);
  const [sampleCourses, setSampleCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedCoursePublicDetails, setSelectedCoursePublicDetails] =
    useState();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [sampleEvents, setSampleEvents] = useState([]);
  const [bulkEvents, setBulkEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [orderTypes, setOrderTypes] = useState([]);
  const [adidasRepEventSearchKey, setAdidasRepEventSearchKey] = useState();
  const [sizeLimitWarningThreshold, setSizeLimitWarningThreshold] = useState();

  //Fix to delete Firestore instance on page unload
  //This is an issue in Safari
  useEffect(() => {
    const unloadCallback = () => {
      const firebaseApp = inititializeFirebase();
      console.log(firebaseApp);
      deleteApp(firebaseApp)
        .then(function () {
          console.log("App deleted successfully");
        })
        .catch(function (error) {
          console.log("Error deleting app:", error);
        });
    };
    window.addEventListener("beforeunload", unloadCallback);
    return async () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  //Database Reset if User logs out
  useEffect(() => {
    if (user == null) {
      setUserHasNoAssociatedCourses(false);
      setCourses([]);
      setSelectedCourseID();
      setUpcomingEvents([]);
      setSampleEvents([]);
      setBulkEvents([]);
      setSelectedEventID();
    }
  }, [user]);

  //If user is adidas rep, monitor their UserDoc
  //get their Typesense Search Key API to view past events
  //get their sizeLimitWarningThreshold
  useEffect(() => {
    if (user == null || user.type !== "adidasRep") return;
    const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
      const userDoc = doc.data();
      setAdidasRepEventSearchKey(userDoc?.eventsSearchAPIKey);
      setSizeLimitWarningThreshold(userDoc?.sizeLimitWarningThreshold);
    });
    return () => unsubscribe();
  }, [user, db]);

  //Monitor CourseID selection and set selected course
  //This is due to latency to firebase and may have to wait for a database fetch
  useEffect(() => {
    if (selectedCourseID != null) {
      const course = [...courses, ...sampleCourses].filter(
        (course) => course.id === selectedCourseID
      )[0];
      if (course != null) {
        setSelectedCourse(course);
      } else {
        if (selectedCoursePublicDetails != null) {
          setSelectedCourse(selectedCoursePublicDetails);
        } else {
          getDoc(
            doc(db, "courses", selectedCourseID, "public", "generalInfo")
          ).then((doc) => {
            let course = doc.data();
            course.id = selectedCourseID;
            setSelectedCoursePublicDetails(course);
            setSelectedCourse(course);
          });
        }
      }
    } else {
      setSelectedCourse();
      setSelectedCoursePublicDetails();
    }
  }, [
    courses,
    sampleCourses,
    selectedCourseID,
    db,
    selectedCoursePublicDetails
  ]);

  //Monitor EventID selection and set selected event
  //This is due to latency to firebase and may have to wait for a database fetch
  useEffect(() => {
    let unsubscribe = () => {};
    if (selectedEventID != null && selectedCourseID != null) {
      if (upcomingEvents.filter((event) => event.id === selectedEventID)[0]) {
        setSelectedEvent(
          upcomingEvents.filter((event) => event.id === selectedEventID)[0]
        );
      } else if (
        bulkEvents.filter((event) => event.id === selectedEventID)[0]
      ) {
        setSelectedEvent(
          bulkEvents.filter((event) => event.id === selectedEventID)[0]
        );
      } else {
        setSelectedEvent();
        unsubscribe = onSnapshot(
          doc(db, "courses", selectedCourseID, "events", selectedEventID),
          (doc) => {
            if (doc.exists()) {
              const event = doc.data();
              event.id = doc.id;

              //convert Timestamp to javascript date
              if (!event.isDemo && !event.isBulkEvent) {
                let eventDate = new Date(event.eventDate.toDate());
                eventDate.setMonth(eventDate.getUTCMonth());
                eventDate.setDate(eventDate.getUTCDate());
                eventDate.setHours(23, 59, 59);
                event.eventDate = eventDate;

                let deadline = new Date(event.deadline.toDate());
                deadline.setMonth(deadline.getUTCMonth());
                deadline.setDate(deadline.getUTCDate());
                deadline.setHours(23, 59, 59);
                event.deadline = deadline;
              }

              event.downloadHistory != null &&
                Object.entries(event.downloadHistory).forEach(
                  ([userID, value]) => {
                    event.downloadHistory[userID] = new Date(value.toDate());
                  }
                );

              setSelectedEvent(event);
            } else {
              setSelectedEvent({});
            }
          }
        );
      }
    } else {
      setSelectedEvent();
      setSelectedEventID();
    }

    return () => unsubscribe();
  }, [upcomingEvents, bulkEvents, selectedCourseID, selectedEventID, db]);

  //Set Article Data on Event Packages
  useEffect(() => {
    if (selectedEvent?.packages == null) return;
    if (
      selectedEvent?.packages?.articleData == null ||
      xor(
        Object.keys(selectedEvent.packages?.articleData),
        Object.keys(selectedEventArticles)
      ).length > 0
    ) {
      setSelectedEvent((prevState) => ({
        ...prevState,
        packages: { ...prevState.packages, articleData: selectedEventArticles }
      }));
    }
  }, [selectedEvent, selectedEventArticles]);

  //Change Event Articles if Event Changes
  useEffect(() => {
    if (
      selectedEventID == null &&
      Object.keys(selectedEventArticles).length > 0
    ) {
      setSelectedEventArticles({});
    } else {
      if (
        selectedEvent?.packages?.allArticles != null &&
        xor(
          Object.keys(selectedEvent?.packages?.allArticles),
          Object.keys(selectedEventArticles)
        ).length > 0
      ) {
        if (Object.keys(selectedEvent?.packages.allArticles).length > 0) {
          retrieveArticlesByIDs(
            Object.keys(selectedEvent?.packages.allArticles)
          )
            .then((articles) => {
              setSelectedEventArticles(articles);
            })
            .catch((error) => {
              console.log(error);
              setSelectedEventArticles({});
            });
        } else {
          setSelectedEventArticles({});
        }
      }
    }
  }, [
    selectedEventID,
    selectedEvent?.packages?.allArticles,
    retrieveArticlesByIDs,
    selectedEventArticles
  ]);

  //If user is Course Admin, monitor "eventsByCourse" state and concat any changes
  useEffect(() => {
    if (Object.keys(eventsByCourse).length === 0) return;
    let events = [];
    Object.keys(eventsByCourse).forEach((courseID) => {
      eventsByCourse[courseID].forEach((event) => {
        events.push(event);
      });
    });
    setUpcomingEvents(events);
  }, [eventsByCourse]);

  useEffect(() => {
    user?.type === "adidasRep" &&
      getDoc(doc(getFirestore(), "generalPrivate", "orderTypes")).then(
        (snapshot) => {
          setOrderTypes(snapshot.data().types);
        }
      );
  }, [user?.type]);

  //*****FIREBASE LISTENERS*****//
  //*****REP COURSES*****//
  useEffect(() => {
    if (user == null || user.type !== "adidasRep") return;
    //Query for courses by Rep
    const repQuery = query(
      collection(db, "courses"),
      where("supplierRepIDs", "array-contains", user.uid),
      orderBy("name")
    );

    let unsubscribe = onSnapshot(repQuery, (querySnapshot) => {
      const repCourses = [];
      querySnapshot.forEach((doc) => {
        //Add the doc id to the course
        let course = doc.data();
        course.id = doc.id;
        if (course.region == null) course.region = "CAN";

        repCourses.push(course);
      });
      setCourses(repCourses);
    });

    if (user.possibleTypes.includes("adidasManager")) {
      getDoc(doc(db, "courses", "00demoCourse")).then((doc) => {
        let course = doc.data();
        course.id = doc.id;
        setSampleCourses([course]);
      });
    }

    return () => unsubscribe();
  }, [user, db]);

  //*****ADMIN COURSES*****//
  useEffect(() => {
    if (user == null || user.type !== "courseAdmin") return;
    //Query for courses by Course Admin
    const courseAdminQuery = query(
      collection(db, "courses"),
      where(`courseAdminsInfo.${user.uid}.isEnabled`, "==", true)
    );
    let unsubscribe = onSnapshot(courseAdminQuery, (querySnapshot) => {
      const adminCourses = [];
      querySnapshot.forEach((doc) => {
        //Add the doc id to the course
        let course = doc.data();
        course.id = doc.id;
        if (course.region == null) course.region = "CAN";

        adminCourses.push(course);
      });
      setCourses(adminCourses);
      if (adminCourses.length === 1) setSelectedCourseID(adminCourses[0].id);
      if (adminCourses.length === 0) setUserHasNoAssociatedCourses(true);
    });

    return () => {
      unsubscribe();
    };
  }, [user, db]);

  //*****ADIDAS MANAGER REGION US SAMPLE EVENTS ******/
  useEffect(() => {
    if (user == null || !user.possibleTypes.includes("adidasManager")) return;

    const unsubscribe = onSnapshot(
      query(
        collection(db, "courses", "00demoCourse", "events"),
        where("isDemo", "==", true)
      ),
      (snapshot) => {
        const sampleEvents = [];
        snapshot.forEach((doc) => {
          sampleEvents.push({ ...doc.data(), id: doc.id });
        });
        setSampleEvents(sampleEvents);
      }
    );

    return () => unsubscribe();
  }, [user, db]);

  //*****REP OR EVENT MANAGER UPCOMING EVENTS*****//
  useEffect(() => {
    if (user == null || !["adidasRep", "eventManager"].includes(user.type))
      return;
    //A comparison date to only retreive upcoming events
    let comparisonDate = new Date();
    comparisonDate.setUTCMonth(comparisonDate.getMonth());
    comparisonDate.setUTCDate(comparisonDate.getDate() - 7);
    comparisonDate.setUTCHours(comparisonDate.getHours());

    //Query for events by Rep or Event Manager
    let q;
    if (user.type === "adidasRep") {
      q = query(
        collectionGroup(db, "events"),
        where("supplierRepIDs", "array-contains", user.uid),
        where("eventDate", ">=", Timestamp.fromDate(comparisonDate))
      );
    } else {
      q = query(
        collectionGroup(db, "events"),
        where("eventManagerIDs", "array-contains", user.uid),
        where("eventDate", ">=", Timestamp.fromDate(comparisonDate))
      );
    }
    let unsubscribe = onSnapshot(q, (querySnapshot) => {
      const dbUpcomingEvents = [];
      querySnapshot.forEach((doc) => {
        if (!doc.data().isDeleted) {
          //Add the doc id to the event
          let event = doc.data();
          event.id = doc.id;

          //Tracking size of firestore event doc
          // const bytes = sizeof(event);
          // console.log(
          //   `${event.eventName} size is ${(bytes / 1024).toFixed(2)} KB`
          // );
          // const bytes2 = sizeof(event.packages);
          // console.log(
          //   `${event.eventName} packages size is ${(bytes2 / 1024).toFixed(
          //     2
          //   )} KB`
          // );
          // const bytes3 = sizeof(event.participants);
          // console.log(
          //   `${event.eventName} participants size is ${(bytes3 / 1024).toFixed(
          //     2
          //   )} KB`
          // );

          event.limitWarnings = {};
          //Only add warnings if the user is
          if (user.type === "adidasRep") {
            event.limitWarnings = warningsForEvent(event);
          }

          //convert Timestamp to javascript date
          let eventDate = new Date(event.eventDate.toDate());
          eventDate.setMonth(eventDate.getUTCMonth());
          eventDate.setDate(eventDate.getUTCDate());
          eventDate.setHours(23, 59, 59);
          event.eventDate = eventDate;

          let deadline = new Date(event.deadline.toDate());
          deadline.setMonth(deadline.getUTCMonth());
          deadline.setDate(deadline.getUTCDate());
          deadline.setHours(23, 59, 59);
          event.deadline = deadline;

          if (event.createdAt) event.createdAt = event.createdAt.toDate();

          event.participants != null &&
            Object.keys(event.participants).forEach((participantID) => {
              event.participants[participantID].invites?.forEach(
                (date, index) =>
                  (event.participants[participantID].invites[index] =
                    date.toDate())
              );
            });
          // const bytes = sizeof(event.particpants);
          // console.log(
          //   `${event.eventName} size is ${(bytes / 1024).toFixed(2)} KB`
          // );
          event.downloadHistory != null &&
            Object.entries(event.downloadHistory).forEach(([userID, value]) => {
              event.downloadHistory[userID] = new Date(value.toDate());
            });
          dbUpcomingEvents.push(event);
        }
      });
      setUpcomingEvents(dbUpcomingEvents.filter((event) => !event.isBulkEvent));
      setBulkEvents(dbUpcomingEvents.filter((event) => event.isBulkEvent));
    });
    return () => {
      unsubscribe();
    };
  }, [user, db, sizeLimitWarningThreshold]);

  //*****COURSE ADMIN UPCOMING EVENTS*****//
  useEffect(() => {
    if (user == null || user.type !== "courseAdmin") return;
    //A comparison date to only retreive upcoming events
    let comparisonDate = new Date();
    comparisonDate.setUTCMonth(comparisonDate.getMonth());
    comparisonDate.setUTCDate(comparisonDate.getDate());
    comparisonDate.setUTCHours(comparisonDate.getHours());

    let unsubscribes = [];

    //Iterate the admins courses (could be part of multiple) and query and listen for events and their changes
    courses.forEach((course) => {
      let adminEventsQuery;
      if (course.courseAdminsInfo[user.uid].superAdmin) {
        adminEventsQuery = query(
          collection(db, "courses", course.id, "events"),
          where("isInitialized", "==", true),
          where("eventDate", ">=", Timestamp.fromDate(comparisonDate))
        );
      } else {
        adminEventsQuery = query(
          collection(db, "courses", course.id, "events"),
          where("eventAdminIDs", "array-contains", user.uid),
          where("isInitialized", "==", true),
          where("eventDate", ">=", Timestamp.fromDate(comparisonDate))
        );
      }

      unsubscribes.push(
        onSnapshot(adminEventsQuery, (querySnapshot) => {
          const dbUpcomingEvents = [];
          querySnapshot.forEach((doc) => {
            if (!doc.data().isDeleted) {
              //Add the doc id to the event
              let event = doc.data();
              event.limitWarnings = {};
              event.id = doc.id;

              //Filter out bulk events... should not be shown to Course Admins
              if (event.isBulkEvent) return;

              //convert Timestamp to javascript date
              let eventDate = new Date(event.eventDate.toDate());
              eventDate.setMonth(eventDate.getUTCMonth());
              eventDate.setDate(eventDate.getUTCDate());
              eventDate.setHours(23, 59, 59);
              event.eventDate = eventDate;

              let deadline = new Date(event.deadline.toDate());
              deadline.setMonth(deadline.getUTCMonth());
              deadline.setDate(deadline.getUTCDate());
              deadline.setHours(23, 59, 59);
              event.deadline = deadline;

              event.participants != null &&
                Object.keys(event.participants).forEach((participantID) => {
                  event.participants[participantID].invites?.forEach(
                    (date, index) =>
                      (event.participants[participantID].invites[index] =
                        date.toDate())
                  );
                });
              event.downloadHistory != null &&
                Object.entries(event.downloadHistory).forEach(
                  ([userID, value]) => {
                    event.downloadHistory[userID] = new Date(value.toDate());
                  }
                );

              dbUpcomingEvents.push(event);
            }
          });
          setEventsByCourse((prevData) => ({
            ...prevData,
            [`${course.id}`]: dbUpcomingEvents
          }));
        })
      );
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [user, db, courses]);

  const getCoursePublicInfo = useCallback(
    async (courseID) => {
      const snapshot = await getDoc(
        db,
        "courses",
        courseID,
        "public",
        "generalInfo"
      );
      console.log(snapshot);
      return snapshot.data();
    },
    [db]
  );

  //Get CourseAdmin Invite
  const getAdminInvite = useCallback(
    async (userID, courseID) => {
      const q = query(
        collection(db, "courses", courseID, "pendingAdmins"),
        where("uid", "==", userID)
      );

      const querySnapshot = await getDocs(q);
      let invites = [];
      querySnapshot.forEach((doc) => {
        let invite = doc.data();
        invite.docID = doc.id;
        invites.push(invite);
      });
      return invites[0];
    },
    [db]
  );

  //Get EventManager Invite
  const getEventManagerInvite = useCallback(
    async (userID, courseID, eventID) => {
      const q = query(
        collection(
          db,
          "courses",
          courseID,
          "events",
          eventID,
          "pendingManagers"
        ),
        where("uid", "==", userID)
      );

      const querySnapshot = await getDocs(q);
      let invites = [];
      querySnapshot.forEach((doc) => {
        let invite = doc.data();
        invite.docID = doc.id;
        invites.push(invite);
      });
      return invites[0];
    },
    [db]
  );

  //***** SETTER METHODS ******/
  const userDidSelectCourse = useCallback((courseID) => {
    setSelectedCourseID(courseID);
  }, []);

  const userDidSelectEvent = useCallback((courseID, eventID) => {
    setSelectedCourseID(courseID);
    setSelectedEventID(eventID);
  }, []);

  //Share variables and methods to children through the provider
  return (
    <DatabaseReaderContext.Provider
      value={{
        userHasNoAssociatedCourses,
        adidasRepEventSearchKey,
        sizeLimitWarningThreshold,
        courses,
        sampleCourses,
        getCoursePublicInfo,
        getAdminInvite,
        getEventManagerInvite,
        selectedCourse,
        userDidSelectCourse,
        upcomingEvents,
        sampleEvents,
        bulkEvents,
        selectedEvent,
        orderTypes,
        userDidSelectEvent
      }}
    >
      {children}
    </DatabaseReaderContext.Provider>
  );
};

export default DatabaseReaderContextProvider;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useDatabaseReader = () => useContext(DatabaseReaderContext);
