import { COLORS, FONTSIZE } from "helpers/designConstants";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

const InactiveError = ({ inModal }) => {
  const { t } = useTranslation();

  return (
    <Page inModal={inModal}>
      <Container>
        <Image src={"/icon-adidas-logo.svg"} />
        <MessageWrapper inModal={inModal}>
          <Message>{t("sign_up_not_open_message")}</Message>
        </MessageWrapper>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  padding: ${({ inModal }) => (inModal ? "8vh 0" : "10vh 0")};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  justify-items: center;
`;

const Image = styled.img`
  width: 150px;
  margin-bottom: 10px;

  @media screen and (max-width: 550px) {
    width: 100px;
  }
`;

const MessageWrapper = styled.div`
  height: 100%;
  display: flex;
  /* align-items: center; */
  padding: ${({ inModal }) => (inModal ? "10vh 0" : "20vh 0 0")};
`;
const Message = styled.div`
  color: ${COLORS.text};
  font-size: ${FONTSIZE.large};
  text-align: center;
`;

export default InactiveError;
