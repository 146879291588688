import React, { Fragment } from "react";
import styled from "styled-components/macro";
import { useLoading, Puff } from "@agney/react-loading";
import { COLORS } from "helpers/designConstants";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Loading = ({ isLoading, withProgress = false, progress = 25 }) => {
  const { containerProps, indicatorEl } = useLoading({
    loading: isLoading,
    loaderProps: {
      // Any props here would be spread on to the indicator element.
      style: { color: "white" },
      valueText: "Loading..."
    },
    indicator: <Puff width='80vw' />
  });

  return (
    <Fragment>
      {isLoading ? (
        <LoadingBackground>
          <LoadingIconContainer
            style={{ backgroundColor: "rgba(0,0,0,0)" }}
            {...containerProps}
          >
            {indicatorEl}
          </LoadingIconContainer>

          <OuterOverlayContainer>
            <InnerOverlayContainer>
              {withProgress ? (
                <CircularProgressbar
                  strokeWidth={12}
                  value={progress}
                  text={`${progress}%`}
                  styles={buildStyles({
                    // textSize: "12px",
                    textColor: `${COLORS.text}`,
                    pathColor: `${COLORS.text}`,
                    trailColor: `${COLORS.tertiary}`
                  })}
                  background={"white"}
                />
              ) : (
                <img src='/icon-adidas-logo.svg' alt='adidasImage' />
              )}
            </InnerOverlayContainer>
          </OuterOverlayContainer>
        </LoadingBackground>
      ) : null}
    </Fragment>
  );
};

export default Loading;

const LoadingBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingIconContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(132, 132, 132, 0.8);
`;

const OuterOverlayContainer = styled.div`
  z-index: 101;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerOverlayContainer = styled.div`
  width: 200px;
  height: 200px;
  opacity: 0.92;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
    height: 100px;
    opacity: 0.5;
    animation: pulse 3s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(0.95);
    }
  }
`;
