import { useCallback } from "react";
import { articleSearchClient } from "services/typesense";
import { chunk } from "lodash";

const useArticleSearchReader = () => {
  const retrieveArticlesByIDs = useCallback(async (ids) => {
    process.env.NODE_ENV === "development" &&
      console.log("Calling to Typesense to get Articles by ID");
    if (ids == null || ids.length === 0) return {};
    try {
      const chunks = chunk(ids, 200);
      let searchRequests = { searches: [] };
      chunks.forEach((chunk) => {
        searchRequests.searches.push({
          collection: `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`,
          filter_by: ids && ids.length ? `id: [${chunk}]` : undefined
        });
      });

      // Search parameters that are common to all searches go here
      let commonSearchParams = {
        q: "*",
        per_page: 200
      };

      let result = await articleSearchClient.multiSearch.perform(
        searchRequests,
        commonSearchParams
      );

      let retrievedArticles = {};
      result.results.forEach((chunk) => {
        chunk.hits.map(
          (hit) => (retrievedArticles[hit.document.id] = hit.document)
        );
      });

      return retrievedArticles;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  }, []);

  return {
    retrieveArticlesByIDs
  };
};

export default useArticleSearchReader;
