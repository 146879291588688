import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Loading from "components/shared/Loading";
import "services/i18n.js";
import * as Sentry from "@sentry/react";

// console.log(process.env.REACT_APP_USER_ENVIRONMENT);
if (["prod", "dev"].includes(process.env.REACT_APP_USER_ENVIRONMENT)) {
  // console.log("Sentry is enabled");
  Sentry.init({
    dsn: "https://7e7c58f11129467294e2123666af135c@o1299279.ingest.sentry.io/4505108932984832",
    release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_USER_ENVIRONMENT}`,
    integrations: [Sentry.browserTracingIntegration()],
    environment: process.env.REACT_APP_USER_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
    ignoreErrors: ["ResizeObserver loop limit exceeded"]
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading isLoading={true} />}>
      <Sentry.ErrorBoundary fallback={<Loading isLoading={true} />}>
        <App />
      </Sentry.ErrorBoundary>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
