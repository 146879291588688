import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  CONSTANTS,
  COLORS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

import ImageComponent from "components/shared/ImageComponent";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const SectionArticleCard = ({
  article,
  customPrice,
  hideMSRP,
  didSelectAddRemove,
  isSelected,
  onZoomArticle
}) => {
  //HOOKS
  const { t, i18n } = useTranslation();

  return (
    <Container isSelectedHighlight={isSelected}>
      <ImageWrapper>
        <Image
          imageURLs={
            article?.imageURLs?.filter(
              (url) => url.includes("_MAIN_") && url.includes("_thumb")
            )[0] || "emptyImagePlaceholder.png"
          }
        />
        <ImageCover />
      </ImageWrapper>
      <InfoWrapper>
        <ArticleInfo>
          <ArticleName>{article?.articleName}</ArticleName>
          <ArticleNumber>{article?.articleNumber}</ArticleNumber>
          <ArticleColor>
            {i18n.language === "en" ? article?.color : article?.color_fr}
          </ArticleColor>
          <ArticleSizes>
            <span>{t("sizes_label")}: </span>
            {article?.displaySizeRange}
          </ArticleSizes>
          <ArticlePriceWrapper>
            {!hideMSRP && (
              <ArticlePrice crossedOut={customPrice != null}>
                <span>{`$ ${article?.retailPrice} MSRP`}</span>
              </ArticlePrice>
            )}
            {customPrice != null && (
              <CustomPrice>{`$ ${
                Number.isInteger(customPrice)
                  ? customPrice
                  : customPrice.toFixed(2)
              }`}</CustomPrice>
            )}
            {hideMSRP && !customPrice && (
              <CustomPrice>{`$ ${
                Number.isInteger(article?.retailPrice)
                  ? article?.retailPrice
                  : article?.retailPrice.toFixed(2)
              }`}</CustomPrice>
            )}
          </ArticlePriceWrapper>
        </ArticleInfo>
        <ActionButtonGroup>
          <ActionButton onClick={() => onZoomArticle(article)}>
            <ZoomInIcon />
          </ActionButton>
          <AddRemoveButton>
            {isSelected ? (
              <EditIcon onClick={() => didSelectAddRemove(article)} />
            ) : (
              <AddIcon onClick={() => didSelectAddRemove(article)} />
            )}
          </AddRemoveButton>
        </ActionButtonGroup>
      </InfoWrapper>
    </Container>
  );
};
const Container = styled.div`
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  background: ${COLORS.pageBackground};
  border-radius: ${CONSTANTS.borderRadius};
  border: 1px solid
    ${({ limitWarnings, isSelected, isSelectedHighlight }) =>
      limitWarnings
        ? COLORS.alertOrange
        : isSelected || isSelectedHighlight
        ? COLORS.green
        : COLORS.secondary};
  width: 100%;
  position: relative;
`;

const ImageWrapper = styled.div`
  width: 45%;
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* touch-action: none;
  user-select: none; */
  position: relative;
`;

const Image = styled(ImageComponent)`
  max-width: 100%;
  max-height: 100%;
  /* touch-action: none;
  user-select: none; */
`;

const ImageCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  opacity: 0;
  z-index: 2;
  user-select: none;
`;

const InfoWrapper = styled.div`
  height: 100%;
  max-width: 55%;
  padding: 5px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ArticleInfo = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
`;

const ArticleName = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  overflow-wrap: break-word;
`;

const ArticleNumber = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleColor = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  overflow-wrap: break-word;
`;

const ArticleSizes = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  text-transform: uppercase;

  & > span {
    font-weight: ${FONTWEIGHT.heavy};
  }
`;

const ArticlePriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ArticlePrice = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${({ crossedOut }) =>
    crossedOut ? FONTWEIGHT.medium : FONTWEIGHT.heavy};
  padding: 5px;
  margin-right: ${({ crossedOut }) => (crossedOut ? "5px" : "0")};
  color: ${COLORS.alertRed};
  position: relative;

  ${({ crossedOut }) =>
    crossedOut &&
    `::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1.5px solid;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);`}
}

  span {
    color: ${COLORS.text};
  }
`;

const CustomPrice = styled.div`
  padding: 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
`;

const ActionButtonGroup = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButton = styled.div`
  margin: 5px;
  width: 30px;
  height: 30px;
  border: 2px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  color: ${COLORS.secondary};
  /* align-self: flex-end; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: ${({ limitWarnings }) => limitWarnings && COLORS.alertOrange};
  color: ${({ limitWarnings }) => limitWarnings && COLORS.alertOrange};

  :hover {
    background: ${({ limitWarnings }) =>
      limitWarnings ? COLORS.alertOrange : COLORS.secondary};
    color: white;
  }
`;

const AddRemoveButton = styled.div`
  margin: 5px;
  width: 30px;
  height: 30px;
  border: 2px solid
    ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.green)};
  border-radius: ${CONSTANTS.borderRadius};
  color: ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.green)};
  /* align-self: flex-end; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: ${(props) =>
      props.isSelected ? COLORS.alertRed : COLORS.green};
    color: white;
  }
`;

export default SectionArticleCard;
