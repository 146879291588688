export const warningsForEvent = (event) => {
  let warnings = {};
  // if (Object.keys(event.packages.selections).length === 0) return warnings;
  Object.entries(event.packages.allArticles).forEach(
    ([articleID, { orderCaps }]) => {
      Object.entries(orderCaps).forEach(([size, limit]) => {
        //If -1 it means no limits were set so track nothing
        if (
          limit !== -1 &&
          !event.notifications.suppressed[articleID]?.includes(size)
        ) {
          const qtySelected = event.packages.selections?.[articleID]?.[size];
          //Only track if the inital limit was greater than the threshold... this limits how many notificaitons will happen
          if (limit > event.notificationThreshold) {
            if (limit - qtySelected <= 0) {
              if (warnings[articleID] == null) warnings[articleID] = {};
              warnings[articleID][size] = 0;
            } else if (limit - qtySelected <= event.notificationThreshold) {
              if (warnings[articleID] == null) warnings[articleID] = {};
              warnings[articleID][size] = limit - qtySelected;
            }
          } else {
            //Still provide a warning if the limit is actually reached
            if (limit !== 0 && limit - qtySelected <= 0) {
              if (warnings[articleID] == null) warnings[articleID] = {};
              warnings[articleID][size] = 0;
            }
          }
        }
      });
    }
  );
  return warnings;
};
