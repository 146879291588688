import React, { Suspense } from "react";
import styled from "styled-components/macro";
import { useImage } from "react-image";

const ImageComponent = ({ imageURLs, className }) => {
  return (
    <Suspense
      fallback={<StyledPlaceholderImg src='emptyImagePlaceholder.png' alt='' />}
    >
      <InnerImageComponent imageURLs={imageURLs} className={className} />
    </Suspense>
  );
};

const InnerImageComponent = ({ imageURLs, className }) => {
  const { src, error } = useImage({
    srcList: imageURLs,
    useSuspense: false
  });

  return (
    <img
      className={className}
      src={!error ? src : "emptyImagePlaceholder.png"}
      alt=''
    />
  );
};

const StyledPlaceholderImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export default ImageComponent;
