import Typesense from "typesense";

export const articleSearchClient = new Typesense.Client({
  nodes: [
    {
      host: window.location.hostname.includes("192.168")
        ? window.location.hostname
        : process.env.REACT_APP_TYPESENSE_HOST,
      port: process.env.REACT_APP_TYPESENSE_PORT,
      protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL
    }
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ARTICLES_SEARCH_API_KEY,
  connectionTimeoutSeconds: 5
});

export const eventSearchClient = (apiKey) => {
  return new Typesense.Client({
    nodes: [
      {
        host: window.location.hostname.includes("192.168")
          ? window.location.hostname
          : process.env.REACT_APP_TYPESENSE_HOST,
        port: process.env.REACT_APP_TYPESENSE_PORT,
        protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL
      }
    ],
    apiKey: apiKey,
    connectionTimeoutSeconds: 5
  });
};

export const emulatorSearchAdminClient = new Typesense.Client({
  nodes: [
    {
      host: window.location.hostname.includes("192.168")
        ? window.location.hostname
        : process.env.REACT_APP_TYPESENSE_HOST,
      port: process.env.REACT_APP_TYPESENSE_PORT,
      protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL
    }
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ARTICLES_SEARCH_API_KEY,
  connectionTimeoutSeconds: 5
});
