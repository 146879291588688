import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Loading from "components/shared/Loading";
import {
  FONTSIZE,
  FONTWEIGHT,
  COLORS,
  CONSTANTS
} from "helpers/designConstants";

import PublicEventHeader from "components/shared/PublicEventHeader";

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import { useBulkOrdersDatabase } from "database/useBulkOrdersDatabase";
import ProductSection from "./ProductSection";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SizeInputModal from "./SizeInputModal";
import useBulkOrderWriter from "database/useBulkOrderWriter";
import Modal from "components/shared/modal/Modal";
import { Button } from "components/shared/standardElements";
import BulkCart from "./BulkCart";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useTransition, animated } from "@react-spring/web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SendIcon from "@material-ui/icons/Send";
import PlaceOrderModal from "./PlaceOrderModal";
import { httpsCallable } from "firebase/functions";
import { getFunctions } from "firebase/functions";
import * as Sentry from "@sentry/react";

const BulkSelection = () => {
  //HOOKS
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const { updateOrder, removeArticleFromCart } = useBulkOrderWriter();

  const {
    isLoading: dbIsLoading,
    initializeStore,
    store,
    currentOrder,
    finishCurrentOrder,
    startNewOrder,
    courseInfo
  } = useBulkOrdersDatabase();

  //STATE
  const [loading, setLoading] = useState(false);
  const [courseID, setCourseID] = useState();
  const [eventID, setEventID] = useState();
  const [showInitialMessageModal, setShowInitialMessageModal] = useState(false);
  const [showOrderFinishedModal, setShowOrderFinishedModal] = useState(false);
  const [mainViewIndex, setMainViewIndex] = useState(0);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [selectedArticleID, setSelectedArticleID] = useState();
  const [copiedSizeRun, setCopiedSizeRun] = useState({});
  const [orderIsSubmitting, setOrderIsSubmitting] = useState(false);
  const [orderSubmissionError, setOrderSubmissionError] = useState();

  const cartArticleCount = useMemo(() => {
    if (!currentOrder) return 0;
    return Object.keys(currentOrder?.items).length;
  }, [currentOrder]);

  const httpSubmitOrder = httpsCallable(
    getFunctions(),
    "http-submitBulkEventOrder"
  );

  const onShowCheckoutModal = async () => {
    setShowCheckoutModal(true);
    const result = await httpSubmitOrder({ warmupPing: true });
    // console.log(result);
  };

  const onPlaceOrder = async (po, name, email) => {
    setOrderSubmissionError();
    setOrderIsSubmitting(true);
    // console.log(courseID, eventID, currentOrder.id, po, email);

    const formatter = Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short"
    });

    const result = await httpSubmitOrder({
      courseID,
      eventID,
      orderID: currentOrder.id,
      po,
      name,
      email,
      orderDate: formatter.format(new Date())
    });
    // console.log(result);
    setOrderIsSubmitting(false);

    if (result.data?.success) {
      //If sucessful reset the view and finish the order
      setMainViewIndex(0);
      finishCurrentOrder();
      setShowCheckoutModal(false);
      setShowOrderFinishedModal(true);
    } else {
      //If there is an error log it to Sentry
      Sentry.captureMessage("Error submitting bulk order", {
        message: result.data?.error
      });
      setOrderSubmissionError(result.data?.error);
    }

    return result;
  };

  //INITIAL SETUP
  useEffect(() => {
    let parsedParams = {};
    const urlParams = new URLSearchParams(search);
    urlParams.forEach((value, key) => (parsedParams[key] = value));
    setCourseID(parsedParams.cid);
    setEventID(parsedParams.eid);
    setLoading(true);
    initializeStore(parsedParams.cid, parsedParams.eid).then(() => {
      setLoading(false);
    });
  }, [initializeStore, search]);

  useEffect(() => {
    if (dbIsLoading === false && store?.isActive) {
      setShowInitialMessageModal(true);
    }
  }, [dbIsLoading, store?.isActive]);

  const onInitialMessageSelection = (selection) => {
    if (selection === "continue") {
      setShowInitialMessageModal(false);
    }
    if (selection === "new") {
      startNewOrder();
      setShowInitialMessageModal(false);
    }
  };

  const onSelectArticle = (article) => {
    setSelectedArticleID(article?.id);
  };

  const onUpdateOrder = (inputData) => {
    console.log(inputData);
    updateOrder(courseID, eventID, currentOrder?.id, {
      [`${selectedArticleID}`]: inputData
    });
    setSelectedArticleID();
  };

  const onRemoveFromCart = () => {
    removeArticleFromCart(
      courseID,
      eventID,
      currentOrder?.id,
      selectedArticleID
    );
    setSelectedArticleID();
  };

  const qtysAvailable = useMemo(() => {
    if (!store || !selectedArticleID) return {};

    const qtysAvailable = {};

    Object.entries(store.allArticles?.[selectedArticleID]?.orderCaps).forEach(
      ([size, qty]) => {
        if (qty === -1) {
          qtysAvailable[size] = -1;
          return;
        } else {
          const selectedQty = store.selections[selectedArticleID]?.[size] ?? 0;
          qtysAvailable[size] = qty - selectedQty < 0 ? 0 : qty - selectedQty;
        }
      }
    );
    return qtysAvailable;
  }, [selectedArticleID, store]);

  const MainView = (index) => {
    if (index === 0) {
      return (
        <Content>
          <ContentHeader>
            <LeftSideHeader>
              {/* Empty for now... can put message here maybe */}
            </LeftSideHeader>
            <RightSideHeader>
              <NextButton
                disabled={!cartArticleCount}
                onClick={
                  cartArticleCount
                    ? () => setMainViewIndex((currentIndex) => currentIndex + 1)
                    : null
                }
              >
                <NextButtonText>
                  <ShoppingCartIcon fontSize='large' />
                  <CartCount>{cartArticleCount}</CartCount>
                </NextButtonText>
                <ArrowForwardIosIcon style={{ height: "18px" }} />
              </NextButton>
            </RightSideHeader>
          </ContentHeader>
          <ProductScrollArea>
            {store?.options.map((option) => (
              <ProductSection
                key={option.id}
                sectionData={option}
                articleData={store?.articleData}
                hideMSRP={store?.hideMSRP}
                allEventArticles={store?.allArticles}
                articlesAreSelectable
                onSelectArticle={onSelectArticle}
                userSelections={currentOrder?.items ?? {}}
              />
            ))}
          </ProductScrollArea>
        </Content>
      );
    } else {
      return (
        <Content>
          <ContentHeader>
            <LeftSideHeader>
              <BackButton
                onClick={() =>
                  setMainViewIndex((currentIndex) => currentIndex - 1)
                }
              >
                <ArrowBackIosIcon style={{ height: "18px" }} />
                <NextButtonText>Back</NextButtonText>
              </BackButton>
            </LeftSideHeader>
            <HeaderTitle>Order Summary</HeaderTitle>
            <RightSideHeader>
              <NextButton onClick={onShowCheckoutModal}>
                <SendIcon style={{ height: "36px", width: "30px" }} />
              </NextButton>
            </RightSideHeader>
          </ContentHeader>
          <ProductScrollArea>
            <BulkCart
              sectionsData={store?.options}
              articleData={store?.articleData}
              hideMSRP={store?.hideMSRP}
              allEventArticles={store?.allArticles}
              userSelections={currentOrder?.items ?? {}}
              onSelectArticle={onSelectArticle}
            />
          </ProductScrollArea>
        </Content>
      );
    }
  };

  const transitions = useTransition(mainViewIndex, {
    from: {
      opacity: 0,
      overflow: "hidden",
      transform: `translate3d(${mainViewIndex === 1 ? "100%" : "-100%"},0,0)`
    },
    enter: {
      opacity: 1,
      overflow: "hidden",
      transform: "translate3d(0%,0,0)"
    },
    leave: {
      opacity: 0,
      overflow: "hidden",
      transform: `translate3d(${mainViewIndex === 1 ? "-100%" : "100%"},0,0)`
    },
    // "initial: null" should only disable the 'from' initial transition, not the subsequent 'leave' transition (on the first manually triggered transition)
    initial: null
  });

  return (
    <Container>
      <>
        <PageHeader>
          <PublicEventHeader
            eventName={store?.eventName}
            courseName={store?.courseName}
            courseLogoURL={courseInfo?.logoURL}
            eventDate={store?.eventDate}
            selectionDeadline={store?.deadline}
          />
        </PageHeader>
        <ContentWrapper>
          {store != null && !dbIsLoading && store?.type !== "bulk" ? (
            <WarningMessage>
              This is an invalid Bulk Purchase Store
            </WarningMessage>
          ) : !store?.isActive && !dbIsLoading ? (
            <WarningMessage>
              <p>Store is not currently open</p>
              <p>Please check back later</p>
            </WarningMessage>
          ) : store == null && !dbIsLoading ? (
            <WarningMessage>Could not load the store</WarningMessage>
          ) : (
            transitions((style, index) => (
              <animated.div style={{ ...style, height: "100%", width: "100%" }}>
                {MainView(index)}
              </animated.div>
            ))
          )}
        </ContentWrapper>
        <Loading isLoading={loading} />
      </>
      {/* Initial Message on Launch */}
      <Modal
        title={"Welcome"}
        show={showInitialMessageModal}
        disableCloseOnOutsideClick
        closeButtonHidden
      >
        <InitialMessageModalWrapper>
          {store?.customMessageOn && store?.customMessage_en && (
            <CustomMessage>
              {store.customMessage_en.replace(/<br>/g, "\n")}
            </CustomMessage>
          )}
          {currentOrder != null && (
            <ContinueOrderMessageWrapper>
              <ContinueOrderMessage>
                <p>There is an order in progress that was started on:</p>
                <p>
                  {currentOrder?.createdAt.toLocaleString("en-US", {
                    dateStyle: "medium",
                    timeStyle: "short"
                  })}
                </p>
                <p>Do you wish to continue with that order?</p>
              </ContinueOrderMessage>
              <Button onClick={() => onInitialMessageSelection("continue")}>
                Continue Order
              </Button>
            </ContinueOrderMessageWrapper>
          )}
          <StartNewOrderMessageWrapper>
            <StartNewOrderMessage>
              {currentOrder != null && (
                <p>
                  If you choose to start a new order, data from the "IN
                  PROGRESS" order will be lost
                </p>
              )}
            </StartNewOrderMessage>
            <Button onClick={() => onInitialMessageSelection("new")}>
              Start New Order
            </Button>
          </StartNewOrderMessageWrapper>
        </InitialMessageModalWrapper>
      </Modal>
      {/* Order Completion Modal */}
      <Modal
        title={"Order Submitted"}
        show={showOrderFinishedModal}
        disableCloseOnOutsideClick
        closeButtonHidden
      >
        <OrderFinishedMessageWrapper>
          <p>
            Your order has been submitted and you will receive a copy in your
            email.
          </p>
          <p>You can close this window or start a new order</p>
          <Button
            onClick={() => {
              startNewOrder();
              setShowOrderFinishedModal(false);
            }}
          >
            Start New Order
          </Button>
        </OrderFinishedMessageWrapper>
      </Modal>
      <SizeInputModal
        show={selectedArticleID != null}
        onClose={() => setSelectedArticleID()}
        article={store?.articleData?.[selectedArticleID]}
        hideMSRP={store?.hideMSRP}
        customPrice={store?.allArticles?.[selectedArticleID]?.customPrice}
        // qtyAvailable={store?.allArticles?.[selectedArticleID]?.orderCaps}
        qtyAvailable={qtysAvailable}
        currentSelections={currentOrder?.items[selectedArticleID]}
        copiedSizeRun={copiedSizeRun}
        setCopiedSizeRun={setCopiedSizeRun}
        onUpdateOrder={onUpdateOrder}
        onRemoveFromCart={onRemoveFromCart}
      />
      <PlaceOrderModal
        show={showCheckoutModal}
        onClose={() => setShowCheckoutModal(false)}
        isSubmitting={orderIsSubmitting}
        onPlaceOrder={onPlaceOrder}
        submissionError={orderSubmissionError}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 1150px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-items: center;
  justify-content: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 30px;
`;

const PageHeader = styled.div`
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  width: 100%;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 0.5fr 0.5fr;
  place-items: center;
  overflow: hidden;
  border-radius: ${CONSTANTS.borderRadius};
  background: ${COLORS.tertiary};
  border: 1px solid ${COLORS.secondary};

  > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`;

const WarningMessage = styled.div`
  font-size: ${FONTSIZE.large};
  color: ${COLORS.text};
  text-transform: uppercase;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  border-radius: ${CONSTANTS.borderRadius};
  background: ${COLORS.tertiary};
`;

const ContentHeader = styled.div`
  width: 100%;
  padding: 10px;
  background: ${COLORS.tertiary};
  border-radius: ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius} 0 0;
  border-bottom: 1px solid ${COLORS.secondary};
  position: sticky;
  top: 0;
  z-index: 10;
  text-transform: uppercase;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px lightgrey;
  z-index: 1;

  @media screen and (min-width: 550px) {
    font-size: ${FONTSIZE.medium};
  }
`;

const LeftSideHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;

  span {
    color: ${({ overLimit }) =>
      overLimit ? COLORS.alertOrange : COLORS.green};
    font-size: ${FONTSIZE.xsmall};
    @media screen and (min-width: 550px) {
      font-size: ${FONTSIZE.small};
    }
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const RightSideHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FONTSIZE.medium};
`;

const BackButton = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1.0)};
  /* position: relative; */
  /* padding-right: 10px; */
  color: ${COLORS.text};
  font-size: ${FONTSIZE.medium};
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "inherit" : "pointer")};
`;

const BackButtonText = styled.div`
  /* padding: 10px 0px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NextButton = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1.0)};
  /* position: relative; */
  /* padding-right: 10px; */
  color: ${COLORS.text};
  font-size: ${FONTSIZE.medium};
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "inherit" : "pointer")};
`;

const NextButtonText = styled.div`
  /* padding: 10px 0px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CartCount = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 12px;
  background: ${COLORS.green};
  color: white;
  padding: 3px;
  min-width: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductScrollArea = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 5px 0 5px;
  /* border: 1px solid ${COLORS.secondary}; */
  border-radius: 0 0 ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius};
`;

const InitialMessageModalWrapper = styled.div`
  /* min-width: 300px; */
  /* min-height: 200px; */
  max-width: 400px;
  max-height: 60vh;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const CustomMessage = styled.div`
  width: 100%;
  padding-bottom: 40px;
  font-size: ${FONTSIZE.small};
  color: ${COLORS.text};
  border-bottom: 1px solid ${COLORS.secondary};
`;

const ContinueOrderMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLORS.secondary};
`;

const ContinueOrderMessage = styled.div`
  width: 100%;
  font-size: ${FONTSIZE.small};
  color: ${COLORS.text};

  p {
    width: 100%;
    text-align: center;
    white-space: pre-wrap;
    margin: 3px 0;

    :last-child {
      margin: 10px 0;
    }
  }
`;

const StartNewOrderMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* padding-bottom: 20px; */
`;

const StartNewOrderMessage = styled.div`
  width: 100%;
  font-size: ${FONTSIZE.small};
  color: ${COLORS.text};

  p {
    width: 100%;
    text-align: center;
    white-space: pre-wrap;
    margin: 3px 0;
  }
`;

const OrderFinishedMessageWrapper = styled.div`
  max-width: 400px;
  max-height: 60vh;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: ${COLORS.text};
  text-align: center;
`;

export default BulkSelection;
