import { doc, getFirestore, updateDoc, deleteField } from "firebase/firestore";
import React from "react";

export default function useBulkOrderWriter() {
  const db = getFirestore();

  const updateOrder = async (courseID, storeID, orderID, orderData) => {
    const dbUpdate = {};
    Object.entries(orderData).forEach(([articleID, orderData]) => {
      dbUpdate[`items.${articleID}`] = orderData;
    });

    return await updateDoc(
      doc(db, "courses", courseID, "events", storeID, "orders", orderID),
      dbUpdate
    );
  };

  const removeArticleFromCart = async (
    courseID,
    storeID,
    orderID,
    articleID
  ) => {
    return await updateDoc(
      doc(db, "courses", courseID, "events", storeID, "orders", orderID),
      { [`items.${articleID}`]: deleteField() }
    );
  };

  return {
    updateOrder,
    removeArticleFromCart
  };
}
