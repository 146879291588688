import { useState } from "react";

const useArticleZoomModal = () => {
  //STATE
  const [isZoomModalShowing, setShowZoomModal] = useState(false);
  const [zoomArticle, setZoomArticle] = useState();
  const [zoomArticleImages, setZoomArticleImages] = useState();

  const toggleArticleZoomModal = () => {
    setShowZoomModal(!isZoomModalShowing);
  };

  const onZoomArticle = (article) => {
    let images = [];
    article?.imageURLs?.forEach((url) => {
      if (url.includes("_thumb")) {
        if (url.includes("_MAIN_")) {
          images.unshift({
            thumbnail: url,
            original: url.includes("_MAIN_thumb")
              ? url.replace("thumb", "")
              : url.replace("_thumb", "")
          });
        } else {
          images.push({
            thumbnail: url,
            original: url.replace("_thumb", "")
          });
        }
      }
    });
    setShowZoomModal(true);
    setZoomArticleImages(images);
    setZoomArticle(article);
  };

  return {
    toggleArticleZoomModal,
    isZoomModalShowing,
    onZoomArticle,
    zoomArticle,
    zoomArticleImages
  };
};

export default useArticleZoomModal;
