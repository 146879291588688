import { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const useGeneralInvite = (eventID) => {
  const [courseID, setCourseID] = useState();
  const [customMessage, setCustomMessage] = useState();
  const [courseInfo, setCourseInfo] = useState({});
  const [eventInfo, setEventInfo] = useState({});
  const [error, setError] = useState();
  const [okToProceed, setOkToProceed] = useState(false);

  const db = getFirestore();

  const addParticipantCloudFunction = httpsCallable(
    getFunctions(),
    "http-addParticipant"
  );

  //Warmup the AddParticipant Function
  useEffect(() => {
    if (
      courseID &&
      Object.keys(courseInfo).length &&
      Object.keys(eventInfo).length &&
      eventInfo.deadline > new Date()
    ) {
      addParticipantCloudFunction({ courseID, eventID, warmupMode: true }).then(
        (result) => {
          if (!result.data.success) {
            setError(result.data.error);
          } else {
            setOkToProceed(true);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseInfo, eventInfo]);

  //Get and set the courseID
  useEffect(() => {
    if (eventID) {
      getDoc(doc(db, "genericLinks", eventID)).then((snapshot) => {
        if (snapshot.exists()) {
          const cid = snapshot.data().courseID;
          setCourseID(cid);
          setCustomMessage({
            enMessage: snapshot.data().enMessage,
            frMessage: snapshot.data().frMessage
          });
        } else {
          setError("existence");
        }
      });
    }
  }, [db, eventID]);

  //Get the Course and Event Info
  useEffect(() => {
    if (courseID && eventID) {
      getDoc(doc(db, "courses", courseID, "public", "generalInfo"))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setCourseInfo(snapshot.data());
          } else {
            setError("existence");
          }
        })
        .catch((error) => setError("existence"));
      getDoc(
        doc(
          db,
          "courses",
          courseID,
          "events",
          eventID,
          "typesenseSyncDocs",
          "typesenseData"
        )
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            const { supplierRepIDs, ...rest } = snapshot.data();
            const event = rest;

            //Transform deadline to 1 second before midnight local time
            const serverDeadline = event.deadline.toDate();
            var offset = serverDeadline.getTimezoneOffset();
            event.deadline = new Date(event.deadline.toDate());
            event.deadline.setTime(serverDeadline.getTime() - offset * 60000);

            event.eventDate = new Date(event.eventDate.toDate());
            setEventInfo(rest);
            if (event.deadline < new Date()) {
              setError("pastDeadline");
            }
          } else {
            setError("existence");
          }
        })
        .catch((error) => setError("existence"));
    }
  }, [courseID, eventID, db]);

  const addParticipant = async (participantName, participantEmail) => {
    return addParticipantCloudFunction({
      courseID,
      eventID,
      participantName,
      participantEmail
    });
  };

  return {
    courseInfo,
    eventInfo,
    customMessage,
    error,
    okToProceed,
    addParticipant
  };
};

export default useGeneralInvite;
