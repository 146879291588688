import inititializeFirebase from "../services/firebase";
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback
} from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  signOut,
  updateEmail,
  updateProfile,
  reauthenticateWithCredential,
  EmailAuthProvider,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence
} from "firebase/auth";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

//INITIALIZE FIREBASE
//DONE HERE AS ITS THE TOP LEVEL ENTRY TO FIREBASE IN THE APP
inititializeFirebase();

//CREATE AUTH CONTEXT
const AuthContext = createContext();

//AUTH CONTEXT PROVIDER TO WRAP AROUND APP
const AuthContextProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const [user, setUser] = useState(null);
  const [authContextLoaded, setAuthContextLoaded] = useState(false);

  //MONITOR USER STATUS
  useEffect(() => {
    setAuthContextLoaded(false);
    const auth = getAuth();
    return onAuthStateChanged(auth, async (firUser) => {
      if (firUser) {
        const idTokenResult = await firUser.getIdTokenResult();
        let tempUser = firUser;
        let userType = idTokenResult.claims.userType;
        let region = idTokenResult.claims.region;
        tempUser.region = region || "CAN";
        tempUser.possibleTypes = userType;
        if (userType.length === 1) {
          tempUser.type = userType[0];
        } else if (
          userType.filter((type) => type !== "adidasManager" && type !== "god")
            .length > 1
        ) {
          tempUser.type = "unknown";
        } else if (userType.includes("adidasRep")) {
          tempUser.type = "adidasRep";
        } else if (userType.includes("courseAdmin")) {
          tempUser.type = "courseAdmin";
        }

        setUser(tempUser);

        //HACK TO MAKE SURE US REPS ONLY GET ENGLISH
        if (tempUser.region === "US") {
          i18n.changeLanguage("en");
        }
        Sentry.setUser({
          id: tempUser.uid,
          username: tempUser.displayName,
          email: tempUser.email
        });

        setAuthContextLoaded(true);
      } else {
        setUser(null);
        Sentry.setUser(null);
        setAuthContextLoaded(true);
      }
    });
  }, [i18n]);

  //SIGNIN USER
  const login = useCallback(async (email, password, keepLoggedIn) => {
    await setPersistence(
      getAuth(),
      keepLoggedIn ? browserLocalPersistence : browserSessionPersistence
    );
    //Returns a User or an Error if no User
    return await signInWithEmailAndPassword(getAuth(), email, password);
  }, []);

  //SIGNOUT USER
  const logout = useCallback(async () => {
    return signOut(getAuth());
  }, []);

  //REAUTHENTICATE USER
  const reauthenticateUser = async (password) => {
    return reauthenticateWithCredential(
      user,
      EmailAuthProvider.credential(user.email, password)
    );
  };

  //CHANGE PASSWORD
  const changePassword = async (newPassword) => {
    return updatePassword(getAuth().currentUser, newPassword);
  };

  //CHANGE USER DETAILS
  const updateUserDetails = async (name, email) => {
    try {
      await updateEmail(getAuth().currentUser, email);
      await updateProfile(getAuth().currentUser, { displayName: name });
      await updateDoc(doc(getFirestore(), "users", user.uid), {
        name: name,
        email: email
      });
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //SEND PASSWORD RESET EMAIL
  const sendPasswordReset = async (email) => {
    return sendPasswordResetEmail(getAuth(), email);
  };

  //UPDATE THE USER TYPE BASED ON USER SELECTION
  const updateUserType = (userType) => {
    setUser((prevUserState) => ({ ...prevUserState, type: userType }));
  };

  return (
    <AuthContext.Provider
      value={{
        authContextLoaded,
        user,
        updateUserType,
        updateUserDetails,
        login,
        logout,
        sendPasswordReset,
        changePassword,
        reauthenticateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useAuth = () => useContext(AuthContext);
