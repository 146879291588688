import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components/macro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Context Imports
import AuthContextProvider from "database/useAuth";
import DatabaseReaderContextProvider from "database/useDatabaseReader";
import ParticipantDatabaseContextProvider from "database/useParticipantDatabase";
import ArticleReaderContextProvider from "database/articles/useArticleReader";

//Helper Imports
import { COLORS, FONTSIZE } from "helpers/designConstants";

//Route Imports
import SuperAdminProtectedRoute from "routes/SuperAdminProtectedRoute";
import { QueryParamProvider } from "use-query-params";

import Loading from "components/shared/Loading";
import GeneralInvite from "components/pages/generalEventInvite/GeneralInvite";
import DemoMainPage from "components/pages/demo/DemoMainPage";
import BulkSelection from "components/pages/publicSelection/bulkSelection/BulkSelection";
import BulkOrdersContextProvider from "database/useBulkOrdersDatabase";

//LAZY LOADING
const DashboardRoute = React.lazy(() => import("routes/DashboardsRoute"));

const ManagerPortal = React.lazy(() =>
  import("components/pages/managerPortal/ManagerPortal")
);

const AdminLogin = React.lazy(() =>
  import("components/pages/adminLogin/AdminLogin")
);
const AdminInviteAccept = React.lazy(() =>
  import("components/pages/courseAdminInviteAccept/AdminInviteAccept")
);
const EventManagerInviteAccept = React.lazy(() =>
  import("components/pages/eventManagerInviteAccept/EventManagerInviteAccept")
);

const ParticipantSelectionRoute = React.lazy(() =>
  import("routes/ParticipantSelectionRoute")
);

const EventPreview = React.lazy(() =>
  import("components/pages/eventPreview/EventPreview")
);

const maintenanceMode = false;

const App = () => {
  if (maintenanceMode) {
    return (
      <MaintenanceWrapper>
        <LogoWrapper>
          <img src={"icon-adidas-logo.svg"} alt='' />
        </LogoWrapper>
        <MaintenanceText>SITE IS DOWN FOR MAINTENANCE</MaintenanceText>
      </MaintenanceWrapper>
    );
  }
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Container>
            <Switch>
              <Route exact path={["/"]}>
                <Route path='/'>
                  <Redirect to='/adminLogin' />
                </Route>
              </Route>
              <Route exact path='/gi'>
                <GeneralInvite />
              </Route>
              <Route exact path='/demo'>
                <DemoMainPage />
              </Route>
              <Route
                exact
                path={[
                  "/adminLogin",
                  "/acceptAdminInvite",
                  "/mainDashboard",
                  "/courseDashboard",
                  "/eventDashboard",
                  // "/storeDashboard",
                  "/upload",
                  "/acceptAdminInvite",
                  "/acceptManagerInvite"
                ]}
              >
                <ArticleReaderContextProvider>
                  <DatabaseReaderContextProvider>
                    <Route exact path='/adminLogin'>
                      <AdminLogin />
                    </Route>
                    <Route
                      exact
                      path={[
                        "/mainDashboard",
                        "/courseDashboard",
                        "/eventDashboard"
                        // "/storeDashboard"
                      ]}
                    >
                      <DashboardRoute />
                    </Route>
                    <SuperAdminProtectedRoute exact path='/upload'>
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <ManagerPortal />
                      </Suspense>
                    </SuperAdminProtectedRoute>
                    <Route exact path='/acceptAdminInvite'>
                      <AdminInviteAccept />
                    </Route>
                    <Route exact path='/acceptManagerInvite'>
                      <EventManagerInviteAccept />
                    </Route>
                  </DatabaseReaderContextProvider>
                </ArticleReaderContextProvider>
              </Route>
              <Route
                exact
                path={[
                  "/participantSelection",
                  "/participantSelection/exists",
                  "/participantSelection/complete",
                  "/participantSelection/finish",
                  "/participantSelection/deadline",
                  "/participantSelection/suspended",
                  "/eventPreview"
                ]}
              >
                <ParticipantDatabaseContextProvider>
                  <Route
                    exact
                    path={[
                      "/participantSelection",
                      "/participantSelection/exists",
                      "/participantSelection/complete",
                      "/participantSelection/finish",
                      "/participantSelection/deadline",
                      "/participantSelection/suspended"
                    ]}
                  >
                    <ParticipantSelectionRoute />
                  </Route>
                  <Route exact path='/eventPreview'>
                    <EventPreview />
                  </Route>
                </ParticipantDatabaseContextProvider>
              </Route>
              <Route exact path='/bulkOrder'>
                <BulkOrdersContextProvider>
                  <BulkSelection />
                </BulkOrdersContextProvider>
              </Route>
            </Switch>
          </Container>
          <StyledToastContainer
            position='top-right'
            theme='light'
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            // draggable
            pauseOnHover
          />
        </QueryParamProvider>
      </BrowserRouter>
    </AuthContextProvider>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  background: ${COLORS.pageBackground};
  /* background: pink; */
  display: flex;
  justify-content: center;
`;

const MaintenanceWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  width: 100px;
`;

const MaintenanceText = styled.div`
  font-size: ${FONTSIZE.xlarge};
`;

// const StyledToastContainer = styled(ToastContainer).attrs({
//   className: "toast-container",
//   toastClassName: "toast",
//   bodyClassName: "body",
//   progressClassName: "progress"
// })`
//   .toast {
//     background: black;
//   }
// `;

const StyledToastContainer = styled(ToastContainer)`
  /* &&&.Toastify__toast-container {
    background: red;
  } */
  /* .Toastify__toast {
    background: black;
  } */
`;

export default App;
