import React, { useEffect, useState } from "react";
import {
  getFirestore,
  query,
  orderBy,
  collectionGroup,
  where,
  onSnapshot
} from "firebase/firestore";
import styled from "styled-components/macro";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

const DemoMainPage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const db = getFirestore();
    const eventQuery = query(
      collectionGroup(db, "packages"),
      where("isDemo", "==", true),
      where("isActive", "==", true),
      where("isInitialized", "==", true),
      orderBy("eventName")
    );

    const unsubscribe = onSnapshot(eventQuery, (querySnapshot) => {
      const events = [];
      querySnapshot.forEach((doc) => {
        events.push({ ...doc.data(), id: doc.id });
      });
      setEvents(events);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Page>
      <Header>
        <ImageWrapper>
          <img src='icon-adidas-logo.svg' alt='adidas Logo' />
        </ImageWrapper>
        <HeaderText>adidas Golf Demo Stores</HeaderText>
      </Header>
      <MainBody>
        <Message>
          <p>Browse and interact with our demo stores below.</p>
          <p>
            You can go through the selection and checkout process to see exactly
            how they will work.
          </p>
          <p>No data will be saved.</p>
        </Message>
        <SiteList>
          {events.map((event) => (
            <EventCard
              key={event.id}
              onClick={() => {
                let domain = "";
                if (window.location.hostname === "localhost") {
                  domain = `http://localhost:${window.location.port}`;
                } else {
                  domain = `https://${window.location.hostname}`;
                }
                window.open(
                  `${domain}/participantSelection?cid=00demoCourse&eid=${event.eventID}`,
                  "_blank",
                  "noreferrer"
                );
              }}
            >
              {event.eventName}
            </EventCard>
          ))}
        </SiteList>
      </MainBody>
    </Page>
  );
};

const Page = styled.div`
  height: 100%;
  width: 100%;
  padding: 3vh 10px 0 10px;
  display: grid;
  grid-template-rows: min-content 1fr;
  justify-items: center;
  width: 100vw;
  padding-bottom: 30px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 550px) {
    height: 100px;
  }
`;

const HeaderText = styled.div`
  padding: 20px;
  font-size: 3em;
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  text-align: center;
  border-bottom: 1px solid ${COLORS.secondary};
  @media screen and (max-width: 550px) {
    font-size: 2em;
  }
`;

const MainBody = styled.div`
  padding: 20px 0;
  height: 100%;
  max-width: 850px;
  display: grid;
  grid-template-rows: min-content 1fr;
  justify-content: center;
`;

const Message = styled.div`
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  text-align: center;
`;

const SiteList = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  gap: 20px;
`;

const EventCard = styled.div`
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  background: ${COLORS.tertiary};
  padding: 10px;
  color: ${COLORS.text};
  cursor: pointer;
  width: 250px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
  text-align: center;

  :hover {
    background: ${COLORS.secondary};
    color: ${COLORS.primary};
  }

  @media screen and (max-width: 550px) {
    width: 95%;
    height: 100px;
  }
`;

export default DemoMainPage;
