import { createContext, useContext, useState, useEffect } from "react";
import { getDoc, doc, getFirestore } from "firebase/firestore";

// import useSearchClientReader from "database/search/useSearchClientReader";
import { useAuth } from "database/useAuth";

//CREATE CONTEXT
const ArticleReaderContext = createContext();

const ArticleReaderContextProvider = ({ children }) => {
  //HOOKS
  const { user } = useAuth();

  //STATE
  const [allSeasons, setAllSeasons] = useState([]);
  const [activeSeasons, setActiveSeasons] = useState([]);
  const [schemas, setSchemas] = useState({});
  const [overallCategories, setOverallCategories] = useState({});
  const [copiedSizeRun, setCopiedSizeRun] = useState({});

  const db = getFirestore();

  useEffect(() => {
    // if (user == null || activeSeasons.length > 0) return;

    if (user == null && activeSeasons.length > 0) {
      setActiveSeasons([]);
      console.log("User is NULL");
      return;
    } else if (activeSeasons.length > 0) {
      console.log("activeSeasons is already set");
      return;
    } else if (user != null && activeSeasons.length === 0) {
      console.log("Setting All Seasons & Active Seasons based on rep region");
      getDoc(doc(db, "general", "seasonInfo")).then((snapshot) => {
        if (user.region === "CAN" || !user.region) {
          setAllSeasons(snapshot.data()["allSeasons"]);
        } else {
          setAllSeasons(snapshot.data()["allSeasons_us"]);
        }
        const activeSeasonsRegionKey =
          user.region === "CAN" || !user.region
            ? "activeSeasons"
            : "activeSeasons_us";
        // const activeSeasonsRegionKey = "activeSeasons";
        if (snapshot.exists() && snapshot.data()[activeSeasonsRegionKey]) {
          let schemas = {};
          Promise.all(
            snapshot.data()[activeSeasonsRegionKey].map(async (season) => {
              const schemaSnapshot = await getDoc(
                doc(db, "general", "seasonInfo", "schemas", season.key)
              );
              if (schemaSnapshot.exists())
                schemas[season.key] = schemaSnapshot.data();
            })
          ).then(() => {
            const activeSeasons = snapshot
              .data()
              [activeSeasonsRegionKey].map((season) => {
                if (season.effectiveCarryoverDate) {
                  return {
                    ...season,
                    effectiveCarryoverDate: new Date(
                      season.effectiveCarryoverDate.toDate()
                    )
                  };
                } else {
                  return season;
                }
              });

            setActiveSeasons(activeSeasons);
            setSchemas(schemas);
          });
        }
      });
    }
  }, [activeSeasons, db, user]);

  useEffect(() => {
    if (user == null) return;
    getDoc(doc(db, "general", "overallCategorySchema")).then((snapshot) => {
      if (snapshot.exists()) {
        setOverallCategories(snapshot.data());
      }
    });
  }, [db, user]);

  return (
    <ArticleReaderContext.Provider
      value={{
        allSeasons,
        activeSeasons,
        schemas,
        overallCategories,
        copiedSizeRun,
        setCopiedSizeRun
      }}
    >
      {children}
    </ArticleReaderContext.Provider>
  );
};

export default ArticleReaderContextProvider;

export const useArticleReader = () => useContext(ArticleReaderContext);
