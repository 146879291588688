import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";

const PublicEventHeader = ({
  eventName,
  courseName,
  eventDate,
  courseLogoURL,
  selectionDeadline
}) => {
  //HOOKS
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <LogoWithMainDetails>
        <AdidasLogo>
          <img src={courseLogoURL || "/icon-adidas-logo.svg"} alt='' />
        </AdidasLogo>
        <EventHeader>
          <EventName>{eventName}</EventName>
          <CourseName>{courseName}</CourseName>
        </EventHeader>
      </LogoWithMainDetails>
      <EventDetails>
        {eventDate != null && (
          <EventDateWrapper>
            {t("event_date")}:
            <DateText>
              {new Date(eventDate).toLocaleString(i18n.language, {
                month: "short",
                day: "numeric",
                year: "numeric"
              })}
            </DateText>
          </EventDateWrapper>
        )}
        {selectionDeadline != null && (
          <EventDateWrapper>
            {t("deadline")}:
            <DateText>
              {new Date(selectionDeadline).toLocaleString(i18n.language, {
                month: "short",
                day: "numeric",
                year: "numeric"
              })}
            </DateText>
          </EventDateWrapper>
        )}
      </EventDetails>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    padding: 5px 0px;
  }
`;

const LogoWithMainDetails = styled.div`
  display: flex;
  align-items: stretch;
  @media screen and (max-width: 550px) {
    padding: 0 5px;
    width: 100%;
  }
`;

const AdidasLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid ${COLORS.secondary};

  img {
    height: 40px;
    @media screen and (min-width: 550px) and (min-height: 500px) {
      height: 70px;
    }
  }
`;

const EventHeader = styled.div`
  color: ${COLORS.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const EventName = styled.div`
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;

  @media screen and (max-width: 550px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const CourseName = styled.div`
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;

  @media screen and (max-width: 550px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const EventDetails = styled.div`
  height: 100%;
  color: ${COLORS.text};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: ${FONTSIZE.small};
  @media screen and (max-width: 550px) {
    border-top: 1px solid ${COLORS.secondary};
    border-bottom: 1px solid ${COLORS.secondary};
    margin-top: 5px;
    padding: 5px 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: ${FONTSIZE.xsmall};
  }
`;
const EventDateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  /* font-size: ${FONTSIZE.small}; */
`;
const DateText = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  padding-left: 5px;
`;

export default PublicEventHeader;
