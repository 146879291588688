import LabelledTextInput from "components/shared/LabelledTextInput";
import Modal from "components/shared/modal/Modal";
import { Button } from "components/shared/standardElements";
import { COLORS, FONTSIZE } from "helpers/designConstants";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";

export default function PlaceOrderModal({
  show,
  onClose,
  onPlaceOrder,
  isSubmitting,
  submissionError
}) {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    resetField
  } = useForm();

  const onSubmit = async (data) => {
    const dataCopy = { ...data };
    await onPlaceOrder(dataCopy.po, dataCopy.name, dataCopy.email1);
    resetField("po");
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Place Order'
      disableCloseOnOutsideClick
      closeButtonHidden={isSubmitting}
    >
      <Content>
        <Message>
          <p>Please enter a PO# and your email address.</p>
          <p>
            A copy of your order will be sent to your adidas rep as well as the
            email below.
          </p>
        </Message>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <AllInputsWrapper>
            <InputAndErrorWrapper>
              <StyledInput
                error={errors.po}
                labelText={"Purchase Order #"}
                id='po'
                name='po'
                {...register("po", { required: "PO is required" })}
              />
              <ErrorMessage>{errors.po && errors.po.message}</ErrorMessage>
            </InputAndErrorWrapper>
            <EmailsWrapper>
              <InputAndErrorWrapper>
                <StyledInput
                  error={errors.name}
                  labelText={"Enter your name"}
                  id='name'
                  name='name'
                  {...register("name", { required: "Your name is required" })}
                />
                <ErrorMessage>
                  {errors.name && errors.name.message}
                </ErrorMessage>
              </InputAndErrorWrapper>
              <InputAndErrorWrapper>
                <StyledInput
                  error={errors.email1}
                  labelText={"Enter your email"}
                  id='email1'
                  name='email1'
                  {...register("email1", {
                    onChange: (e) => {
                      setValue("email1", e.target.value.trim());
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address"
                    },
                    validate: {
                      notEmpty: (value) =>
                        value.trim().length > 0 || "Invalid email address"
                    }
                  })}
                />
                <ErrorMessage>
                  {errors.email1 && errors.email1.message}
                </ErrorMessage>
              </InputAndErrorWrapper>
              <InputAndErrorWrapper>
                <StyledInput
                  error={errors.email2}
                  labelText={"Confirm your email"}
                  id='email2'
                  name='email2'
                  {...register("email2", {
                    onChange: (e) => {
                      setValue("email2", e.target.value.trim());
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address"
                    },
                    validate: (value) =>
                      value === getValues("email1") || "Emails must match"
                  })}
                />
                <ErrorMessage>
                  {errors.email2 && errors.email2.message}
                </ErrorMessage>
              </InputAndErrorWrapper>
            </EmailsWrapper>
          </AllInputsWrapper>
          <Button disabled={isSubmitting} type='submit'>
            Submit
          </Button>

          <SubmissionInfo isError={submissionError != null}>
            {isSubmitting
              ? "Please wait while your order is submitted.  This make take a moment, do not close this page."
              : submissionError
              ? "There was an error submitting your order. Please wait a few minute and try again. If the problem persists, please contact your adidas rep."
              : " "}
          </SubmissionInfo>
        </StyledForm>
      </Content>
    </Modal>
  );
}

const Content = styled.div`
  padding: 0 10px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  /* padding: 0px 20px; */
  color: ${COLORS.text};
`;

const StyledForm = styled.form`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const AllInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 10px; */
`;

const EmailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 25px 0;
`;

const InputAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledInput = styled(LabelledTextInput)``;

const ErrorMessage = styled.div`
  height: 15px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  padding-top: 0px;
  padding-right: 5px;
`;

const SubmissionInfo = styled.div`
  padding: 10px 0;
  transform-text: uppercase;
  color: ${({ isError }) => (isError ? COLORS.alertRed : COLORS.alertOrange)};
  font-size: ${FONTSIZE.small};
  text-align: center;
`;
