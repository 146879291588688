import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";

import { t } from "i18next";
import { MiniButton } from "components/shared/standardElements";

const BulkCartArticle = ({
  onSelectEditArticle,
  selectedQty,
  article,
  hideMSRP,
  allowEdit
}) => {
  const { i18n } = useTranslation();

  return (
    <Content>
      <ImageWrapper>
        {article?.imageURLs?.length > 0 ? (
          <Image
            src={
              article.imageURLs.filter(
                (url) => url.includes("_MAIN_") && url.includes("_thumb")
              )[0]
            }
            alt='img'
          />
        ) : (
          <Image
            placeholder='true'
            src={"emptyImagePlaceholder.jpg"}
            alt='img'
          />
        )}
      </ImageWrapper>
      <InfoWrapper>
        <ArticleName>{article?.articleName}</ArticleName>
        <ArticleNumber>{article?.articleNumber}</ArticleNumber>
        <ArticleColor>
          {i18n.language === "en" ? article?.color : article?.color_fr}
        </ArticleColor>
        <ArticlePriceWrapper>
          {!hideMSRP && (
            <ArticlePrice
              crossedOut={article?.customPrice !== article?.retailPrice}
            >
              <span>{`$ ${article?.retailPrice} MSRP`}</span>
            </ArticlePrice>
          )}
          {(hideMSRP || article?.customPrice !== article?.retailPrice) && (
            <CustomPrice>{`$ ${
              Number.isInteger(article.customPrice)
                ? article.customPrice
                : article.customPrice?.toFixed(2)
            }`}</CustomPrice>
          )}
        </ArticlePriceWrapper>
      </InfoWrapper>
      <OrderWrapper>
        {article?.userFriendlySizes?.map((size) => (
          <SizeColumn key={size}>
            <SizeHeader>{size}</SizeHeader>
            <SizeQty isDash={!selectedQty[size]}>
              {selectedQty[size] || "-"}
            </SizeQty>
          </SizeColumn>
        ))}
      </OrderWrapper>
      <ButtonsWrapper>
        {allowEdit && (
          <MiniButton onClick={() => onSelectEditArticle(article)}>
            Edit
          </MiniButton>
        )}
      </ButtonsWrapper>
    </Content>
  );
};

const Content = styled.div`
  padding: 5px 5px;
  width: 100%;
  display: grid;
  gap: 0 15px;
  grid-template-columns: max-content minmax(auto, 1fr);
  grid-template-rows: max-content max-content max-content;
  overflow: hidden;
  /* border-bottom: 1px solid ${COLORS.secondary}; */
  border-radius: ${CONSTANTS.borderRadius};
  border: 1px solid ${COLORS.secondary};
  background: ${COLORS.pageBackground};
`;

const ImageWrapper = styled.div`
  height: 150px;
  width: 150px;
  grid-column: 1;
  grid-row: 1 / -1;
  align-self: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 550px) {
    grid-row: 1 / 2;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const InfoWrapper = styled.div`
  grid-row: 1;
  grid-column: 2 / -1;
  padding: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const ArticleName = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleNumber = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleColor = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  /* overflow-wrap: break-word; */
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArticlePriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ArticlePrice = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${({ crossedOut }) =>
    crossedOut ? FONTWEIGHT.medium : FONTWEIGHT.heavy};
  padding: 5px;
  margin-right: ${({ crossedOut }) => (crossedOut ? "5px" : "0")};
  color: ${COLORS.alertRed};
  position: relative;

  ${({ crossedOut }) =>
    crossedOut &&
    `::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1.5px solid;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);`}
}

  span {
    color: ${COLORS.text};
  }
`;

const CustomPrice = styled.div`
  padding: 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
`;

const OrderWrapper = styled.div`
  justify-self: start;
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  grid-row: 2;
  padding: 5px;
  border-radius: ${CONSTANTS.borderRadius};
  background: ${COLORS.tertiary};

  @media screen and (max-width: 550px) {
    margin-top: 10px;
    grid-column: 1 / -1;
    justify-self: stretch;
  }
`;

const SizeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
  padding: 5px;
`;

const SizeHeader = styled.div`
  width: 45px;
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  border-bottom: 1px solid ${COLORS.secondary};
  display: flex;
  justify-content: center;
`;

const SizeQty = styled.div`
  width: 45px;
  font-size: ${FONTSIZE.medium};
  font-weight: ${({ isDash }) =>
    isDash ? FONTWEIGHT.medium : FONTWEIGHT.heavy};
  color: ${COLORS.text};
  display: flex;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  grid-row: 3;
  grid-column: 2;
  justify-self: end;
  display: flex;
  gap: 5px;
  padding: 10px 0 0;
  align-self: center;
`;

export default BulkCartArticle;
