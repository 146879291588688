import React from "react";
import styled from "styled-components/macro";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import { useTranslation } from "react-i18next";

const ContactInfo = ({ contactInfo }) => {
  const { t } = useTranslation();

  return (
    <CourseContactWrapper>
      <ContactTitle>{t("contact_info_title")}</ContactTitle>
      <ContactLineItem>{contactInfo?.companyName}</ContactLineItem>
      <ContactLineItem>{contactInfo?.name}</ContactLineItem>
      <ContactLineItem>{contactInfo?.phone}</ContactLineItem>
      <ContactLineItem>{contactInfo?.email}</ContactLineItem>
    </CourseContactWrapper>
  );
};

const CourseContactWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.text};
  padding: 15px 0;
`;

const ContactTitle = styled.div`
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
  padding: 5px;
`;

const ContactLineItem = styled.div``;

export default ContactInfo;
