import Modal from "components/shared/modal/Modal";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";
import { Button, MiniButton } from "components/shared/standardElements";
import NumberFormat from "react-number-format";
import { useForm, Controller } from "react-hook-form";

export default function SizeInputModal({
  show,
  onClose,
  article,
  customPrice,
  hideMSRP,
  qtyAvailable,
  currentSelections,
  copiedSizeRun,
  setCopiedSizeRun,
  onUpdateOrder,
  onRemoveFromCart
}) {
  const { t, i18n } = useTranslation();
  const { handleSubmit, reset, control, watch } = useForm({});
  const currentFormValues = watch();

  const [unpastedSizes, setUnpastedSizes] = useState([]);

  // const copySizeRun = () => {
  //   const currentValues = getValues();
  //   let sizeRunToCopy = {};
  //   currentValues[article?.id].forEach((cap, index) => {
  //     sizeRunToCopy[article.userFriendlySizes[index]] = cap;
  //   });
  //   setCopiedSizeRun(sizeRunToCopy);
  //   toast.success(
  //     t("copied_size_run_notification", {
  //       articleNumber: article.articleNumber
  //     })
  //   );
  // };

  // const pasteSizeRun = () => {
  //   let unPasted = [];
  //   article.userFriendlySizes.forEach((size, index) => {
  //     if (size in copiedSizeRun) {
  //       setValue(`${article?.id}.${index}`, copiedSizeRun[size]);
  //     } else {
  //       unPasted.push(size);
  //     }
  //     setUnpastedSizes(unPasted);
  //   });
  // };

  // const clearSizeRun = () => {
  //   const sizes = Object.keys(getValues(`${article?.id}`));
  //   sizes.forEach((size) => {
  //     setValue(`${article?.id}.${size}`, 0);
  //   });
  //   setUnpastedSizes([]);
  // };

  const parseFormValues = (data) => {
    const translatedData = {};
    data[article?.id].forEach((qty, index) => {
      translatedData[article.userFriendlySizes[index]] = qty ?? 0;
    });
    onUpdateOrder(translatedData);
    setUnpastedSizes([]);
    reset();
  };

  const onRemoveArticle = () => {
    onRemoveFromCart();
    onCloseModal();
  };

  const onCloseModal = () => {
    reset();
    setUnpastedSizes([]);
    onClose();
  };

  return (
    <Modal
      show={show}
      onClose={onCloseModal}
      title={article?.articleName}
      maxWidth={"85vw"}
      disableCloseOnOutsideClick
    >
      <Content>
        <ImageWrapper>
          {article?.imageURLs?.length > 0 ? (
            <Image
              src={
                article.imageURLs.filter(
                  (url) => url.includes("_MAIN_") && url.includes("_thumb")
                )[0]
              }
              alt='img'
            />
          ) : (
            <Image
              placeholder='true'
              src={"emptyImagePlaceholder.jpg"}
              alt='img'
            />
          )}
        </ImageWrapper>
        <InfoWrapper>
          <ArticleName>{article?.articleName}</ArticleName>
          <ArticleNumber>{article?.articleNumber}</ArticleNumber>
          <ArticleColor>
            {i18n.language === "en" ? article?.color : article?.color_fr}
          </ArticleColor>
          <ArticlePriceWrapper>
            {!hideMSRP && (
              <ArticlePrice
                crossedOut={
                  customPrice != null && customPrice !== article?.retailPrice
                }
              >
                <span>{`$ ${article?.retailPrice} MSRP`}</span>
              </ArticlePrice>
            )}
            {customPrice != null && customPrice !== article?.retailPrice && (
              <CustomPrice>{`$ ${
                Number.isInteger(customPrice)
                  ? customPrice
                  : customPrice.toFixed(2)
              }`}</CustomPrice>
            )}
            {hideMSRP && !customPrice && (
              <CustomPrice>{`$ ${
                Number.isInteger(article?.retailPrice)
                  ? article?.retailPrice
                  : article?.retailPrice.toFixed(2)
              }`}</CustomPrice>
            )}
          </ArticlePriceWrapper>
        </InfoWrapper>
        <StyledForm id='sizeInputForm'>
          <SizeInputWrapper>
            <LabelBox>
              {/* TRANSLATE */}
              <AvailableTitle>{"AVAILABLE"}:</AvailableTitle>
              <QtyInputTitle>{"ORDER"}:</QtyInputTitle>
            </LabelBox>
            <ScrollableInputContainer>
              {article?.userFriendlySizes.map((friendlySize, index) => {
                const databaseSize = article.sizes[index];

                return (
                  <SizeContainer key={friendlySize}>
                    <SizeTitle>{friendlySize}</SizeTitle>
                    <AvailablePerSize>
                      {qtyAvailable?.[friendlySize] >= 0
                        ? qtyAvailable?.[friendlySize]
                        : qtyAvailable?.[friendlySize] === -1
                        ? "∞"
                        : "-"}
                    </AvailablePerSize>
                    <Controller
                      render={({ field }) => {
                        return (
                          <SizeInput
                            $unPastedHighlight={
                              unpastedSizes.includes(friendlySize)
                                ? true
                                : false
                            }
                            onValueChange={(v) => {
                              field.onChange(
                                v.floatValue != null ? v.floatValue : null
                              );
                            }}
                            value={field.value != null ? field.value : ""}
                            displayType='input'
                            type='text'
                            thousandSeparator={false}
                            allowNegative={false}
                            decimalScale={0}
                            isAllowed={({ floatValue }) => {
                              return (
                                floatValue == null ||
                                (floatValue >= 0 &&
                                  (floatValue <= qtyAvailable?.[friendlySize] ||
                                    qtyAvailable?.[friendlySize] === -1))
                              );
                            }}
                            disabled={
                              qtyAvailable?.[friendlySize] === 0 && !field.value
                            }
                          />
                        );
                      }}
                      name={`${article?.id}.${index}`}
                      defaultValue={
                        currentSelections?.[friendlySize] > 0
                          ? currentSelections[friendlySize]
                          : null
                      }
                      control={control}
                    />
                  </SizeContainer>
                );
              })}
            </ScrollableInputContainer>
            {/* <CopyPasteArea>
              <CopyPasteButton
                disabled={qtyAvailable == null}
                type='button'
                onClick={copySizeRun}
              >
                {t("copy_size_run_button")}
              </CopyPasteButton>
              <CopyPasteButton
                type='button'
                disabled={Object.keys(copiedSizeRun).length === 0}
                onClick={pasteSizeRun}
              >
                {t("paste_size_run_button")}
              </CopyPasteButton>
              <CopyPasteButton type='button' onClick={clearSizeRun}>
                {t("clear_button")}
              </CopyPasteButton>
            </CopyPasteArea> */}
          </SizeInputWrapper>
        </StyledForm>
        <Buttons>
          {currentSelections != null && (
            <Button destructive onClick={onRemoveArticle}>
              Remove All
            </Button>
          )}
          <Button
            green
            disabled={Object.keys(currentFormValues).length === 0}
            onClick={handleSubmit(parseFormValues)}
          >
            {currentSelections == null
              ? t("add_to_cart_button")
              : //TRANSLATE
                "Update Cart"}
          </Button>
        </Buttons>
      </Content>
    </Modal>
  );
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  overflow: hidden;
  @media screen and (max-width: 550px) {
    grid-template-columns: 50% 1fr;
  }
`;

const ImageWrapper = styled.div`
  grid-column: 1;
  grid-row: 1 / 3;
  align-self: center;
  padding: 10px;

  @media screen and (max-width: 550px) {
    grid-column: 1;
    grid-row: 1;
  }
`;

// const RightSide = styled.div`
//   width: 100%;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
// `;

const Image = styled.img`
  max-width: 250px;
  max-height: 300px;

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }
`;

const InfoWrapper = styled.div`
  grid-column: 2;
  grid-row: 1;
  padding: 2px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  /* width: 200px; */
  overflow: hidden;
  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

const ArticleName = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleNumber = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleColor = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  /* overflow-wrap: break-word; */
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArticlePriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ArticlePrice = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${({ crossedOut }) =>
    crossedOut ? FONTWEIGHT.medium : FONTWEIGHT.heavy};
  padding: 5px;
  margin-right: ${({ crossedOut }) => (crossedOut ? "5px" : "0")};
  color: ${COLORS.alertRed};
  white-space: nowrap;
  position: relative;

  ${({ crossedOut }) =>
    crossedOut &&
    `::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1.5px solid;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);`}
}

  span {
    color: ${COLORS.text};
  }
`;

const CustomPrice = styled.div`
  width: 100%;
  padding: 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
`;

const StyledForm = styled.form`
  width: 100%;
  overflow: hidden;
  grid-column: 2;
  grid-row: 2;

  @media screen and (max-width: 550px) {
    padding: 0 10px;
    grid-column: 1 / -1;
    grid-row: 2;
  }
`;

const SizeInputWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  padding: 15px 0;
  gap: 5px 0;
  border-top: 1px solid ${COLORS.secondary};
`;

const LabelBox = styled.div`
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  white-space: nowrap;
  padding-right: 5px;
  padding-bottom: 15px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
`;

const AvailableTitle = styled.div`
  padding-bottom: 20px;
  padding-right: 5px;
  text-transform: uppercase;
`;

const QtyInputTitle = styled.div`
  padding-bottom: 7px;
  padding-right: 5px;
  text-transform: uppercase;
`;

const ScrollableInputContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 15px;
`;

const SizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 52px;
  /* margin: 5px; */
  flex-shrink: 0;
`;

const SizeTitle = styled.div`
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid ${COLORS.secondary};
  width: 100%;
  text-align: center;
`;

const SizeInput = styled(NumberFormat)`
  width: 45px;
  text-align: center;
  border: 2px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 5px;
  margin-top: 5px;
  background-color: ${COLORS.tertiary};
  font-size: ${FONTSIZE.small};
  color: ${(props) => {
    if (props.$orangeHighlight) {
      return COLORS.alertOrange;
    } else if (props.$redHighlight) {
      return COLORS.alertRed;
    } else {
      return undefined;
    }
  }};
  border-color: ${(props) => {
    if (props.$orangeHighlight) {
      return COLORS.alertOrange;
    } else if (props.$redHighlight) {
      return COLORS.alertRed;
    } else {
      return undefined;
    }
  }};
  -webkit-appearance: none;

  &:focus {
    outline: none !important;
  }

  border-color: ${(props) => {
    if (props.$unPastedHighlight) {
      return COLORS.alertOrange;
    }
  }};

  opacity: ${(props) => {
    if (props.disabled) {
      return 0.4;
    }
  }};
`;

const AvailablePerSize = styled.div`
  margin: 8px 0 4px;
  color: ${COLORS.text};
`;

const CopyPasteArea = styled.div`
  width: 100%;
  display: flex;
  grid-column: 2;
  grid-row: 2;

  @media screen and (max-width: 550px) {
    grid-column: 1 / -1;
    justify-content: center;
  }
`;

const CopyPasteButton = styled(MiniButton)`
  margin-right: 5px;
`;

const Buttons = styled.div`
  grid-column: 2;
  grid-row: 3;
  justify-self: end;
  align-self: flex-end;
  margin: 5px;
  display: flex;
  gap: 10px;

  @media screen and (max-width: 550px) {
    grid-column: 1 / -1;
    justify-self: center;
  }
`;

const RemoveAllButton = styled(Button)`
  border: 1px solid ${COLORS.alertRed};
  color: ${COLORS.alertRed};
`;

const SubmitButton = styled(Button)``;
