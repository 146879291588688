import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from "react";

import {
  getFirestore,
  collection,
  doc,
  getDoc,
  query,
  onSnapshot
} from "firebase/firestore";

import { deleteApp } from "firebase/app";

import { xor } from "lodash";

import inititializeFirebase from "services/firebase";
import { useAuth } from "./useAuth";
// import useArticleSearchReader from "./articles/useArticleSearchReader";
import useEventArticleReader from "database/articles/useEventArticleReader";

//CREATE AUTH CONTEXT
const ParticipantDatabaseContext = createContext();

//AUTH CONTEXT PROVIDER TO WRAP AROUND APP
const ParticipantDatabaseContextProvider = ({ children }) => {
  //INTERNAL
  const db = getFirestore();
  const { authContextLoaded, user, login, logout } = useAuth();
  // const { retrieveArticlesByIDs } = useArticleSearchReader();
  const { retrieveArticlesByIDs } = useEventArticleReader();

  //CONTEXT STATE
  const [userData, setUserData] = useState();
  const [courseInfo, setCourseInfo] = useState();
  const [eventInfo, setEventInfo] = useState();
  const [eventPackages, setEventPackages] = useState();
  const [eventArticles, setEventArticles] = useState({});
  const [participantInfo, setParticipantInfo] = useState();
  const [participantExists, setParticipantExists] = useState(true);

  const loginParticipant = useCallback(
    async (courseID, eventID, participantID, password) => {
      try {
        //console.log(authContextLoaded, user?.possibleTypes);
        if (participantID != null || (authContextLoaded && user == null)) {
          await login(`participant@${eventID}.com`, password);
        }
        setUserData({ courseID, eventID, participantID });
        return;
      } catch (error) {
        console.log(error);
      }
    },
    [login, authContextLoaded, user]
  );

  //Fix to delete Firestore instance on page unload
  //This is an issue in Safari
  useEffect(() => {
    const unloadCallback = () => {
      const firebaseApp = inititializeFirebase();
      console.log(firebaseApp);
      deleteApp(firebaseApp)
        .then(function () {
          console.log("App deleted successfully");
        })
        .catch(function (error) {
          console.log("Error deleting app:", error);
        });
    };
    window.addEventListener("beforeunload", unloadCallback);
    return async () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  //Course Info
  useEffect(() => {
    if (user == null || userData == null) return;
    getDoc(doc(db, "courses", userData.courseID, "public", "generalInfo")).then(
      (doc) => {
        setCourseInfo(doc.data());
      }
    );
  }, [db, user, userData]);

  //Event Info
  useEffect(() => {
    if (user == null || userData == null) return;
    getDoc(
      doc(
        db,
        "courses",
        userData.courseID,
        "events",
        userData.eventID,
        "typesenseSyncDocs",
        "typesenseData"
      )
    ).then((doc) => {
      setEventInfo(doc.data());
    });
  }, [db, user, userData]);

  //Event Packages
  useEffect(() => {
    if (user == null || userData == null) return;
    const q = query(
      collection(
        db,
        "courses",
        userData.courseID,
        "events",
        userData.eventID,
        "packages"
      )
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let eventPackages = querySnapshot.docs[0].data();
      eventPackages.articleData = {};

      //convert Timestamp to javascript date and manage local timezone
      if (eventPackages.eventDate.toString().length > 0) {
        let eventDate = new Date(eventPackages.eventDate.toDate());
        eventDate.setMonth(eventDate.getUTCMonth());
        eventDate.setDate(eventDate.getUTCDate());
        eventDate.setHours(23, 59, 59);
        eventPackages.eventDate = eventDate;

        let deadline = new Date(eventPackages.deadline.toDate());
        deadline.setMonth(deadline.getUTCMonth());
        deadline.setDate(deadline.getUTCDate());
        deadline.setHours(23, 59, 59);
        eventPackages.deadline = deadline;
      } else {
        let eventDate = new Date(Date.now() + 20 * 24 * 60 * 60 * 1000);
        eventDate.setMonth(eventDate.getUTCMonth());
        eventDate.setDate(eventDate.getUTCDate());
        eventDate.setHours(23, 59, 59);
        eventPackages.eventDate = eventDate;

        let deadline = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
        deadline.setMonth(deadline.getUTCMonth());
        deadline.setDate(deadline.getUTCDate());
        deadline.setHours(23, 59, 59);
        eventPackages.deadline = deadline;
      }

      setEventPackages(eventPackages);
    });
    return () => unsubscribe();
  }, [user, userData, db]);

  //Set Article Data on Event Packages
  useEffect(() => {
    if (eventPackages == null) return;
    if (
      eventPackages.articleData == null ||
      xor(Object.keys(eventPackages.articleData), Object.keys(eventArticles))
        .length > 0
    ) {
      setEventPackages((prevState) => ({
        ...prevState,
        articleData: eventArticles
      }));
    }
  }, [eventPackages, eventArticles]);

  //Get Event Articles and modify if the event articles change
  useEffect(() => {
    if (
      eventPackages?.allArticles != null &&
      xor(Object.keys(eventPackages.allArticles), Object.keys(eventArticles))
        .length > 0
    ) {
      retrieveArticlesByIDs(Object.keys(eventPackages.allArticles))
        .then((articles) => {
          setEventArticles(articles);
        })
        .catch((error) => {
          console.log(error);
          setEventArticles({});
        });
    }
  }, [eventPackages?.allArticles, retrieveArticlesByIDs, eventArticles]);

  //Get Participant Info
  useEffect(() => {
    if (user == null || userData == null || userData?.participantID == null)
      return;
    const q = query(
      doc(
        db,
        "courses",
        userData.courseID,
        "events",
        userData.eventID,
        "participants",
        userData.participantID
      )
    );
    getDoc(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          let participantData = snapshot.data();
          participantData.id = snapshot.id;
          setParticipantInfo(participantData);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setParticipantInfo();
          setParticipantExists(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user, userData, db]);

  //RETURN STATEMENT FOR COMPONENT
  return (
    <ParticipantDatabaseContext.Provider
      value={{
        loginParticipant,
        courseInfo,
        eventInfo,
        eventPackages,
        participantInfo,
        participantExists,
        logout
      }}
    >
      {children}
    </ParticipantDatabaseContext.Provider>
  );
};

export default ParticipantDatabaseContextProvider;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useParticipantDatabase = () =>
  useContext(ParticipantDatabaseContext);
